import { useSelector } from "react-redux";
import classes from "./ConfirmationMessage.module.scss";
import OutlinedButton from "./OutlinedButton";
import words from "../../assets/words";
import Button from "./Button";
import Img from '../../assets/images/question.svg'
const ConfirmationMessage = ({ message, confirm, onHide, confirmLoading, cancel, data,img }) => {
  const language = useSelector((state) => state.auth.language);
  return (
    <div className={classes.container}>
      {data ? <img src={data?.cardImage} alt="Confirm" style={{borderRadius: "50%"}}/> : <></>}
      {data ? <p>{data?.fullName}</p> : <></>}
      {img && (
        <img className="my-3" src={Img} alt="question" />
      )}
      <p>{message}</p>
      <div className={classes.actionBtns}>
        <div>
          <OutlinedButton onClick={cancel ? cancel : onHide}>
            {words.NO[language]}
          </OutlinedButton>
        </div>
        <div>
          <Button onClick={confirm} loading={confirmLoading}>
            {words.YES[language]}
          </Button>
        </div>
      </div>
    </div>
  )
}

export default ConfirmationMessage