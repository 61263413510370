import { createSlice } from "@reduxjs/toolkit";

export const users = createSlice({
    name: 'users',
    initialState: {
        deletedUsersIds: JSON.parse(sessionStorage.getItem("deletedUsersIds"))  || [],
        currentPage: sessionStorage.getItem("usersPage") || 1,
        contactsFilter: JSON.parse(sessionStorage.getItem("contactsFilter")) || {
            endDate:null,
            startDate: null
          },
    },
    reducers: {
        saveUsersIds: (state, action) => {
            state.deletedUsersIds = action.payload;
            sessionStorage.setItem("deletedUsersIds", JSON.stringify(action.payload));
        },
       clearUsersIds: (state) => {
            state.deletedUsersIds = [];
            sessionStorage.removeItem("deletedUsersIds");
        },
        saveUsersPage: (state, action) => {
            state.currentPage = action.payload;
            sessionStorage.setItem("usersPage", action.payload)
        },
        clearUsersPage: (state, action) => {
            state.currentPage = 1;
            sessionStorage.removeItem("usersPage")
        },
        saveContactsFilter: (state, action) => {
            state.contactsFilter = action.payload;
            sessionStorage.setItem("contactsFilter", JSON.stringify(action.payload))
          },
          clearContactsFilter: (state, action) => {
            state.contactsFilter = {
              endDate:null,
              startDate: null
            }
            sessionStorage.removeItem("contactsFilter")
          },
    }
})

export const usersReducer = users.reducer;
export const { saveUsersIds, clearUsersIds, saveUsersPage, clearUsersPage,saveContactsFilter,clearContactsFilter} = users.actions;