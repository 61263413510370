import { gql } from "@apollo/client";

export const UPDATE_CORPORATE_INFO = gql `
mutation UpdateMyCorporateInformation($input: UpdateMyCorporateInformationInput!, $corporatePicture: Upload, $removeCorporatePicture: Boolean) {
  updateMyCorporateInformation(input: $input, corporatePicture: $corporatePicture, removeCorporatePicture: $removeCorporatePicture) {
    code
    success
    message
    data {
      _id
      fullName
      email
      isEmailVerified
      phoneNumber
      countryCode
      isCompletedInformation
      position
      corporateName
      profilePictureUrl
      webFirebaseToken
    }
  }
}
`

export const CREATE_CORPORATE_CARD_DESIGN = gql `
mutation CreateCorporateBusinessCardDesign($cardTheme: String, $primaryColor: String, $secondaryColor: String, $font: FontEnum, $fontColor: String, $cardTextAlignment: CardTextAlignment, $assiggnQrLogo: Boolean, $logo: Upload, $qrColor: String, $backgroundColor: String) {
  createCorporateBusinessCardDesign(cardTheme: $cardTheme, primaryColor: $primaryColor, secondaryColor: $secondaryColor, font: $font, fontColor: $fontColor, cardTextAlignment: $cardTextAlignment, assiggnQRLogo: $assiggnQrLogo, logo: $logo, qrColor: $qrColor, backgroundColor: $backgroundColor) {
    code
    success
    message
    data {
      _id
    }
  }
}
`

export const CREATE_CORPORATE_BUSINESS_CARD = gql `
mutation CreateCorporateBusinessCard($input: CreateCorporateBusinessCardInput, $image: Upload, $designId: ID!, $isAdminCard: Boolean!) {
  createCorporateBusinessCard(input: $input, image: $image, designId: $designId, isAdminCard: $isAdminCard) {
    code
    success
    message
  }
}
`


export const UPLOAD_EXCEL = gql `
mutation UploadExcel($path: Upload!, $multiLanguage: Boolean!, $cardLanguage1: String, $cardLanguage2: String, $cardTextAlignment1: String, $cardTextAlignment2: String) {
  uploadExcel(path: $path, multiLanguage: $multiLanguage, cardLanguage1: $cardLanguage1, cardLanguage2: $cardLanguage2, cardTextAlignment1: $cardTextAlignment1, cardTextAlignment2: $cardTextAlignment2) {
    code
    success
    message
  }
}
`
export const REVIEW_AND_SUBMIT = gql `
mutation SubmitExcelData($confirmation: Boolean!, $designId: ID!, $canEdit: Boolean) {
  submitExcelData(confirmation: $confirmation, DesignId: $designId, canEdit: $canEdit) {
    code
    success
    message
  }
}
`

export const UPDATE_CORPORATE_DESIGN = gql `
mutation UpdateCorporateDesign($cardTheme: String, $primaryColor: String, $secondaryColor: String, $font: FontEnum, $fontColor: String, $cardTextAlignment: CardTextAlignment, $assiggnQrLogo: Boolean, $logo: String, $qrColor: String, $designId: ID!, $uploadLogo: Upload, $backgroundColor: String) {
  updateCorporateDesign(cardTheme: $cardTheme, primaryColor: $primaryColor, secondaryColor: $secondaryColor, font: $font, fontColor: $fontColor, cardTextAlignment: $cardTextAlignment, assiggnQRLogo: $assiggnQrLogo, logo: $logo, qrColor: $qrColor, designId: $designId, uploadLogo: $uploadLogo, backgroundColor: $backgroundColor) {
    code
    success
    message
  }
}
`

export const UPDATE_CORPORATE_BUSINESS_CARD = gql `
mutation UpdateCorporateBusinessCard($cardId: ID!, $email: String!, $phoneNumber: String!, $countryCode: String!, $designId: ID!, $isUpdatedQr: Boolean!, $image: Upload, $removeImage: Boolean, $socialMediaLinks: [SocialMediaLinkInput], $languages: [languageInput], $canEdit: Boolean) {
  updateCorporateBusinessCard(cardId: $cardId, email: $email, phoneNumber: $phoneNumber, countryCode: $countryCode, designId: $designId, isUpdatedQR: $isUpdatedQr, image: $image, removeImage: $removeImage, socialMediaLinks: $socialMediaLinks, languages: $languages, canEdit: $canEdit) {
    code
    success
    message
  }
}
`

export const DELETE_CORPORATE_BUSINESS_CARD = gql `
mutation DeleteCorpareteBusinessCards($cardIds: [ID]!, $isAll: Boolean) {
  deleteCorpareteBusinessCards(cardIds: $cardIds, isAll: $isAll) {
    code
    success
    message
  }
}
`

export const CREATE_CHECKOUT_SESSION = gql `
mutation CreateCheckoutSession($successUrl: String!) {
  createCheckoutSession(successURL: $successUrl) {
    code
    success
    message
    sessionURL
  }
}
`

export const CREATE_FREE_TRIAL_CHECKOUT_SESSION = gql `
mutation CreateFreeTrailCheckoutSession($successUrl: String!) {
  createFreeTrailCheckoutSession(successURL: $successUrl) {
    code
    success
    message
    sessionURL
  }
}
`

export const PAY_AN_INVOICE = gql `
mutation PayAnInvoice($invoiceId: String!) {
  payAnInvoice(invoiceId: $invoiceId) {
    code
    success
    message
    data {
      invoiceId
      invoiceNumber
      status
      quantity
      amountDue
      created
      InvoicePdf
      paid
    }
  }
}
`

export const PROGRESS_UPLOAD = gql `
subscription UploadProgress {
  uploadProgress {
    code
    success
    message
    progress {
      total
      completed
    }
  }
}
`
export const SUBMIT_PROGRESS = gql `
subscription SubmitProgress {
  submitProgress {
    code
    success
    message
    progress {
      total
      completed
    }
  }
}
`

export const UPDATE_MESSAGE_AS_READ = gql `
mutation UpdateMessageAsRead($messageId: ID!) {
  updateMessageAsRead(messageId: $messageId) {
    code
    success
    message
  }
} 
`

export const UPDATE_PAYMENT_INFORMATION = gql `
mutation UpdatePaymentInformation($successUrl: String!) {
  updatePaymentInformation(successURL: $successUrl) {
    code
    success
    message
    portalSessionURL
  }
}
`

export const UPDATE_FIREBASE_TOKEN = gql `
mutation UpdateFirebaseTokenForCorporate($firebaseToken: String!) {
  updateFirebaseTokenForCorporate(firebaseToken: $firebaseToken) {
    code
    success
    message
  }
}
`
export const CREATE_CUSTOMER_SUPPORT_TICKET = gql `
mutation CreateCustomerSupportTicket($reason: String, $description: String, $customDesignImage: Upload) {
  createCustomerSupportTicket(reason: $reason, description: $description, customDesignImage: $customDesignImage) {
    code
    success
    message
    data {
      _id
      ticketId
      reason {
        key
        value
      }
      description
      isActive
      isDeleted
      createdBy
      updatedBy
      createdAt
      updatedAt
    }
  }
}
`
 export const TRANSFER_CONTACTS=gql`
 mutation TransferContacts($userId: ID!, $usersIds: [ID], $allUsers: Boolean) {
  transferContacts(userId: $userId, usersIds: $usersIds, allUsers: $allUsers) {
    code
    success
    message
  }
}`
export const UPDATE_BUSINESS_CARDS_AS_PRIVATE = gql `
mutation UpdateBusinessCardsAsPrivateForCorporate($status: Boolean!, $businessCardsIds: [ID], $isAll: Boolean) {
  updateBusinessCardsAsPrivateForCorporate(status: $status, businessCardsIds: $businessCardsIds, isAll: $isAll) {
    code
    success
    message
  }
}
`

export const CREATE_SUB_BUSINESS_CARD = gql `
mutation CreateSubBusinessCard($input: languageInput) {
  createSubBusinessCard(input: $input) {
    code
    success
    message
  }
}
`

export const UPDATE_SUB_BUSINESS_CARD = gql `
mutation UpdateSubBusinessCard($input: languageInput) {
  updateSubBusinessCard(input: $input) {
    code
    success
    message
  }
}
`

export const CANCEL_SUBSCIPTION = gql `
mutation CancelSubscription {
  cancelSubscription {
    code
    success
    message
  }
}
`

export const SUNSCIBED_SUCCESS = gql `
subscription SubscribedSuccessfully {
  subscribedSuccessfully {
    code
    success
    message
    data {
      _id
    }
  }
}
`

export const APPLY_PROMO_CODE = gql `
mutation ApplyPromoCodeSubscription($promoCode: String!) {
  applyPromoCodeSubscription(promoCode: $promoCode) {
    code
    success
    message
  }
}
`

export const CANCEL_EXCEL = gql `
mutation CancelUploadExcel {
  cancelUploadExcel {
    code
    success
    message
  }
}
`