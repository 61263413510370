import { createPortal } from "react-dom";
import Modal from "react-bootstrap/Modal";
import { useSelector } from "react-redux";
import classes from "./withModal.module.scss";
import close from "../../assets/images/Close.png"

const withModal = (Component) => (props) => {
  const language = useSelector((state) => state.auth.language);

  let modalClasses = [classes.modal];

  if (props.dialogClassName) {
    modalClasses.push(props.dialogClassName);
  }

  if (language === "ar") {
    modalClasses = modalClasses.filter((c) => c !== c.modal);
    modalClasses.push(classes.rtlModal);
  }

  return createPortal(
    <Modal
      centered
      animation={true}
      scrollable={false}
      show={props.show}
      onHide={props.onHide}
      dialogClassName={modalClasses}
      backdrop={"static"}
    >
      {/* <>
      <Modal.Header closeButton={props.showCloseBtn === false ? false : true} className={classes.modalHeader}>
          <Modal.Title>{props.title}</Modal.Title>
        </Modal.Header>
      </> */}
      <Modal.Body>

        <div className={classes.closeBtn} onClick={props.onHide}>
          {
            props.showCloseBtn === false ? <></> :
              <img src={close} alt="close pic" />
          }
        </div>
        {
          props?.title &&
          <h4 className="text-center fw-bold mb-4">{props.title}</h4>
        }
        <Component {...props} />
      </Modal.Body>

    </Modal>,
    document.getElementById("modal-root")
  );
};

export default withModal;
