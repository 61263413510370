import { gql } from "@apollo/client";

export const REGISTER_CORPORATE = gql`
mutation RegisterACorporate($input: RegisterACorporateInput!) {
  registerACorporate(input: $input) {
    code
    success
    message
    token
    data {
      _id
      fullName
      email
      isEmailVerified
      phoneNumber
      countryCode
      isCompletedInformation
      position
      corporateName
      profilePictureUrl
      subscriptionId {
        _id
      }
    }
  }
}
`;
export const LOGIN = gql `
mutation Login($email: String!, $password: String!) {
  login(email: $email, password: $password) {
    code
    success
    message
    token
    data {
      _id
      fullName
      email
      isEmailVerified
      phoneNumber
      countryCode
      isCompletedInformation
      position
      corporateName
      profilePictureUrl
      webFirebaseToken
      subscriptionId {
        _id
        isSubscribed
      }
    }
  }
}
`
export const SEND_OTP = gql `
mutation SendOTPForCorporate($email: String!) {
  sendOTPForCorporate(email: $email) {
    code
    success
    message
  }
}
`
export const CHECK_OTP = gql `
mutation CheckOTPForCorporate($otp: String!) {
  checkOTPForCorporate(OTP: $otp) {
    code
    success
    message
    token
    data {
      _id
      fullName
      email
      isEmailVerified
      phoneNumber
      countryCode
      isCompletedInformation
      position
      corporateName
      profilePictureUrl
      webFirebaseToken
      subscriptionId {
        _id
      }
    }
  }
}
`
export const CREATE_CORPORATE_INFO = gql `
mutation CompleteMyCorporateInformation($input: CompleteMyCorporateInformationInput!) {
  completeMyCorporateInformation(input: $input) {
    code
    success
    message
    token
    data {
      _id
      fullName
      email
      isEmailVerified
      phoneNumber
      countryCode
      isCompletedInformation
      position
      corporateName
      profilePictureUrl
      subscriptionId {
        _id
      }
    }
  }
}
`
export const CHANGE_PASSWORD = gql `
mutation UpdateCorporatePassword($currentPassword: String, $newPassword: String!, $confirmPassword: String!) {
  updateCorporatePassword(currentPassword: $currentPassword, newPassword: $newPassword, confirmPassword: $confirmPassword) {
    code
    success
    message
  }
}
`
export const LOGOUT = gql `
mutation Logout {
  logout {
    code
    message
    success
  }
}
`

export const CHECK_IF_EMAI_EXIST = gql `
mutation CheckIfEmailExistsForCorporate($email: String!) {
  checkIfEmailExistsForCorporate(email: $email) {
    code
    success
    message
  }
}
`

export const UPDATE_PASSWORD = gql `
mutation UpdateCorporatePassword($newPassword: String!, $confirmPassword: String!) {
  updateCorporatePassword(newPassword: $newPassword, confirmPassword: $confirmPassword) {
    code
    success
    message
  }
}`

export const CHECK_EMAIL_NOT_EXIST = gql `
mutation CheckIfEmailDoesNotExistsForCorporate($email: String!) {
  checkIfEmailDoesNotExistsForCorporate(email: $email) {
    code
    success
    message
  }
}
`