const words = {
  ERROR: {
    ar: "تم حدوث خطأ!",
    en: "An Error Occurred!",
    tu: "Giriş Yap",
    ru: "Войти",
    fr: "S'identifier",
  },
  SAVE: {
    ar: 'حفظ',
    en: 'Save',
    tu: "Giriş Yap",
    ru: "Войти",
    fr: "S'identifier",
  },
  YOUR_MESSAGE_HAS_BEEN_SENT_SUCCESSFULLY: {
    en: "Your message has been sent successfully",
    ar: "تم ارسال الرساله بنجاح",
    fr: "Nos fonctionnalités",
    tu: "Özelliklerimiz",
    ru: "Наши особенности",
  },
  SAVE_CHANGES: {
    en: 'Do you want to cancel editing?',
    ar: 'هل تريد الغاء التعديل؟',
    tu: "",
    ru: "",
    fr: "",
  },
  HELP: {
    en: 'Open Ticket',
    ar: 'أرسل شكوى',
    tu: "",
    ru: "",
    fr: "",
  },
  CHOOSE_PROBLEM: {
    en: 'Choose problem',
    ar: 'أختر مشكله ',
    tu: "",
    ru: "",
    fr: ""
  },
  PRIVACY_CARD: {
    en: 'Privacy card',
    ar: 'بطاقة الخصوصية',
    tu: "",
    ru: "",
    fr: "",
  },
  DO_YOU_WANT_TO_DISCARD_YOUR_CHAGES: {
    en: "Do you want to discard your changes?",
    ar: "هل تريد التخلص من التغييرات الخاصة بك؟",
    tu: "",
    ru: "",
    fr: "",
  },
  YOUR_CARD_DESIGN_HAS_BEEN_SENT_SUCCESSFULLY: {
    en: "Your Card design has been Sent successfully",
    ar: " تم ارسال تصميم البطاقة بنجاح",
    tu: "",
    ru: "",
    fr: "",
  },
  WE_WILL_RESPONSE_YOU_WITHIN_TWO_DAYS_WORKING_HOURS: {
    en: "We will response you within 2 days working hours",
    ar: "سوف نرد عليك في غضون ساعات العمل لمدة يومين",
    tu: "",
    ru: "",
    fr: "",
  },
  CANCEL: {
    ar: 'الغاء',
    en: 'Cancel',
    tu: "",
    ru: "",
    fr: "",
  },
  CANCEL_EDIT: {
    ar: ' الغاءالتحديث',
    en: 'Cancel Changes',
    tu: "",
    ru: "",
    fr: "",
  },
  UPLOAD_ERROR: {
    ar: 'حفظ',
    en: 'Couldn\'t Upload Excel sheet please try again',
    tu: "Giriş Yap",
    ru: "Войти",
    fr: "S'identifier",
  },
  BUSINESS_CARD: {
    ar: 'بطاقة اعمال',
    en: 'Business card',
    tu: "Giriş Yap",
    ru: "Войти",
    fr: "S'identifier",
  },

  MORE_COHERENT: {
    ar: 'شبكة أكثر تماسكًا مع بطاقة الأعمال الرقمية Swipy. الآن يمكنك إنشاء بطاقات متعددة اللغات.',
    en: 'More Coherent networking with Swipy digital ',
    tu: "",
    ru: "",
    fr: "",
  },
  MORE_COHERENT2: {
    ar: '',
    en: 'business card.',
    tu: "",
    ru: "",
    fr: "",
  },
  MORE_COHERENT3: {
    ar: "",
    en: 'Now you could create multilinguistic cards.',
    tu: "",
    ru: "",
    fr: "",
  },
  LANGUAGE: {
    ar: 'اللغة',
    en: 'Language',
    tu: "",
    ru: "",
    fr: "",
  },
  ARABIC: {
    ar: 'العربية',
    en: 'Arabic',
    tu: "",
    ru: "",
    fr: "",
  },
  ENGLISH: {
    ar: 'الانجليزية',
    en: 'English',
    tu: "",
    ru: "",
    fr: "",
  },
  SELECT_LANGUAGE: {
    en: "Select Language",
    ar: "اختار اللغة",
    tu: "",
    ru: "",
    fr: "",
  },
  LOADING: {
    ar: 'حفظ',
    en: 'Loading',
    tu: "Giriş Yap",
    ru: "Войти",
    fr: "S'identifier",
  },
  NO_MORE_OPTIONS: {
    ar: 'حفظ',
    en: 'No more options',
    tu: "Giriş Yap",
    ru: "Войти",
    fr: "S'identifier",
  },
  CHANGE_YOUR_EMAIL: {
    ar: 'تغيير البريد الألكتروني',
    en: 'Change your Email',
    tu: "Giriş Yap",
    ru: "Войти",
    fr: "S'identifier",
  },
  CHANGE_YOUR_PASSWORD: {
    ar: 'تغيير كلمه المرور ',
    en: 'Change your password',
    tu: "Giriş Yap",
    ru: "Войти",
    fr: "S'identifier",
  },
  CHOOSE_YOUR_PLAN: {
    en: "Choose your plan",
    ar: "اختر خطتك",
    tu: "Planınızı seçin",
    ru: "Выберите свой план",
    fr: "Choisissez votre forfait"
  },
  YOUR_PASS_CHANGED: {
    ar: 'تم تغيير كلمة السر بنجاح',
    en: 'Your Password Changed Successfully',
    tu: "",
    ru: "",
    fr: "",
  },
  NEXT: {
    ar: 'التالي',
    en: 'Next',
    tu: "Giriş Yap",
    ru: "Войти",
    fr: "S'identifier",
  },
  WEBSITE: {
    ar: 'الموقع',
    en: 'Website',
    tu: "Giriş Yap",
    ru: "Войти",
    fr: "S'identifier",
  },
  DONE: {
    ar: 'تم',
    en: 'Done',
    tu: "",
    ru: "",
    fr: "",
  },
  PRIVACY_CONTENT: {
    en: "Your business card's QR code will not be visible or accessible to anyone else unless you choose to share it. It serves as a notice to remind you that your card information is safeguarded and can only be shared at your discretion.",
    ar: "لن يكون رمز الاستجابة السريعة الخاص ببطاقة عملك مرئيًا أو يمكن لأي شخص آخر الوصول إليه إلا إذا اخترت مشاركته. إنه بمثابة إشعار لتذكيرك بأن معلومات بطاقتك محمية ولا يمكن مشاركتها إلا وفقًا لتقديرك.",
    tu: "Kartvizitinizin QR kodu, siz paylaşmayı seçmediğiniz sürece başkaları tarafından görülmeyecek veya erişilebilir olmayacaktır. Kart bilgilerinizin korunduğunu ve yalnızca sizin takdirinize bağlı olarak paylaşılabileceğini size hatırlatmak için bir bildirim görevi görür.",
    ru: "QR-код вашей визитной карточки не будет виден или доступен кому-либо еще, если вы не решите поделиться им. Оно служит напоминанием о том, что данные вашей карты защищены и могут быть переданы только по вашему усмотрению.",
    fr: "Le code QR de votre carte de visite ne sera ni visible ni accessible à quiconque, sauf si vous choisissez de le partager. Il sert d'avis pour vous rappeler que les informations de votre carte sont protégées et ne peuvent être partagées qu'à votre discrétion."
  },
  GO_TO_BILLING: {
    ar: 'الذهاب الى الفواتير',
    en: 'Go to Billing',
    tu: "",
    ru: "",
    fr: "",
  },
  CONTACT_US: {
    ar: 'تواصل معنا',
    en: 'Contact Us',
    tu: "",
    ru: "",
    fr: "",
  },
  CARDLINK: {
    ar: 'رابط البطاقة',
    en: 'Card Link',
    tu: "",
    ru: "",
    fr: "",
  },
  CARD: {
    ar: 'بطاقة',
    en: 'Card',
    tu: "",
    ru: "",
    fr: "",
  },
  COLORS: {
    ar: 'الالوان',
    en: 'Colors',
    tu: "",
    ru: "",
    fr: "",
  },
  COLOR: {
    ar: 'لون',
    en: 'Color',
    tu: "",
    ru: "",
    fr: "",
  },
  QR: {
    en: "QR",
    ar: "QR",
    tu: "",
    ru: "",
    fr: "",
  },
  FONT: {
    ar: 'الخط',
    en: 'Font',
    tu: "",
    ru: "",
    fr: "",
  },
  RESET: {
    ar: 'اعادة تعيين',
    en: 'Reset',
    tu: "",
    ru: "",
    fr: "",
  },
  UPGRADE_NOW: {
    ar: 'ترقية الان',
    en: 'Upgrade Now',
    tu: "",
    ru: "",
    fr: "",
  },
  UPDATE_SUBSCRIPTION_DETTAILS: {
    ar: "تحديث تفاصيل الاشتراك",
    en: "Update Subscription Details",
    tu: "",
    ru: "",
    fr: "",
  },
  YOUR_CARD: {
    ar: 'بطاقتك',
    en: 'Your Card',
    tu: "",
    ru: "",
    fr: "",
  },
  USER_CARD: {
    ar: 'بطاقة المستخدم',
    en: 'User Card',
    tu: "",
    ru: "",
    fr: "",
  },
  MY_CARD: {
    ar: 'بطاقتي',
    en: 'My Card',
    tu: "",
    ru: "",
    fr: "",
  },
  ERROR_MESSAGE: {
    ar: "برجاء التواصل بالدعم",
    en: "Please Contact Support",
    tu: "Giriş Yap",
    ru: "Войти",
    fr: "S'identifier",
  },
  MESSAGE: {
    ar: " الرساله ",
    en: "Message",
    tu: "Giriş Yap",
    ru: "Войти",
    fr: "S'identifier",
  },
  ENTER_VERIFICATION_CODE: {
    ar: "برجاء ادخال كود التفعيل",
    en: "Please enter the verification code we just sent",
    tu: "Giriş Yap",
    ru: "Войти",
    fr: "S'identifier",
  },
  VERIFICATION_CODE: {
    ar: "برجاء ادخال كود التفعيل",
    en: "Verification code",
    tu: "Giriş Yap",
    ru: "Войти",
    fr: "S'identifier",
  },
  MAKE_SURE_YOU_ENTERED_CORRECT_EMAIL: {
    ar: "برجاء ادخال كود التفعيل",
    en: "Please make sure you entered your email correctly",
    tu: "Giriş Yap",
    ru: "Войти",
    fr: "S'identifier",
  },
  TO_YOUR_EMAIL: {
    ar: "برجاء ادخال كود التفعيل",
    en: "to your Email.",
    tu: "Giriş Yap",
    ru: "Войти",
    fr: "S'identifier",
  },
  YOUR_INFO: {
    ar: "بياناتك",
    en: "Your Info",
    tu: "Giriş Yap",
    ru: "Войти",
    fr: "S'identifier",
  },
  ORGANIZATION_INFO: {
    ar: "",
    en: "Organization Info",
    tu: "Giriş Yap",
    ru: "Войти",
    fr: "S'identifier",
  },
  PLEASE_MAKE_SURE: {
    ar: "تأكد منادخالك للبريد الألكتروني الصحيح",
    en: "Please make sure you entered your email correctly",
    tu: "Giriş Yap",
    ru: "Войти",
    fr: "S'identifier",
  },
  FULL_NAME: {
    ar: "الأسم بالكامل",
    en: "Full Name",
    tu: "Ad Soyad",
    ru: "Полное имя",
    fr: "Nom et prénom",
  },
  EMAIL: {
    ar: "البريد الألكتروني",
    en: "Email",
    tu: "Giriş Yap",
    ru: "Войти",
    fr: "S'identifier",
  },
  PRIVACY: {
    ar: "الخصوصية",
    en: "Privacy",
    tu: "Giriş Yap",
    ru: "Войти",
    fr: "S'identifier",
  },
  PUBLIC: {
    ar: "عام",
    en: "Public",
    tu: "Giriş Yap",
    ru: "Войти",
    fr: "S'identifier",
  },
  VIEW_CARD: {
    ar: "",
    en: "View Card",
    tu: "",
    ru: "",
    fr: ""
  },
  WELCOME_TO: {
    ar: "مرحبا بك في",
    en: "Welcome to",
    tu: "Giriş Yap",
    ru: "Войти",
    fr: "S'identifier",
  },
  PASSWORD: {
    ar: "كلمه المرور",
    en: "password",
    tu: "Giriş Yap",
    ru: "Войти",
    fr: "S'identifier",
  },
  CURRENT_PASSWORD: {
    ar: "كلمه المرور",
    en: "Current password",
    tu: "Giriş Yap",
    ru: "Войти",
    fr: "S'identifier",
  },
  NEW_PASSWORD: {
    ar: "كلمه المرور",
    en: "New password",
    tu: "Giriş Yap",
    ru: "Войти",
    fr: "S'identifier",
  },
  LOG_IN: {
    ar: "تسجيل الدخول",
    en: "Login",
    tu: "Giriş Yap",
    ru: "Войти",
    fr: "S'identifier",
  },
  SIGN_UP: {
    en: "Sign up",
    ar: "تسجيل",
    tu: "Kayıt Ol",
    ru: "Зарегистрироваться",
    fr: "S'inscrire",
  },
  DO_NOT_HAVE_AN_ACCOUNT: {
    ar: "ليس لديك حساب",
    en: "Don't have an account?",
    tu: "Giriş Yap",
    ru: "Войти",
    fr: "S'identifier",
  },
  EDIT_CARD: {
    ar: "تعديل البطاقة",
    en: "Edit Card",
    tu: "Şifremi Unuttum ?",
    ru: "Забыли пароль ?",
    fr: "Mot de passe oublié ?",
  },
  EDIT_YOUR_INFO: {
    ar: "تعديل بيانات",
    en: "Edit Your Info ",
    tu: "Şifremi Unuttum ?",
    ru: "Забыли пароль ?",
    fr: "Mot de passe oublié ?",
  },
  FORGET_PASSWORD: {
    ar: "نسيت كلمة المرور ؟",
    en: "Forgot Password ?",
    tu: "Şifremi Unuttum ?",
    ru: "Забыли пароль ?",
    fr: "Mot de passe oublié ?",
  },
  MINIMUM_8_CHARACTERS: {
    ar: "الحد الأدنى 8 أحرف",
    en: "Minimum 8 characters",
    tu: "Devam et",
    ru: "Продолжить",
    fr: "Continuer",
  },
  CONTINUE_LATER: {
    ar: "اكمل لاحقا",
    en: "Continue Later",
    tu: "",
    ru: "",
    fr: "",
  },
  AT_LEAST_1_LOWERCASE: {
    ar: "حرف صغير واحد على الأقل (a-z)",
    en: "Has At Least 1 Lowercase Character (a-z)",
    tu: "Devam et",
    ru: "Продолжить",
    fr: "Continuer",
  },
  GET_IN_TOUCH_DESC: {
    ar: "حرف صغير واحد على الأقل (a-z)",
    en: "Get In touch",
    tu: "Devam et",
    ru: "Продолжить",
    fr: "Continuer",
  },
  AT_LEAST_1_UPPERCASE: {
    ar: "حرف كبير واحد على الأقل (a-z)",
    en: "Has At Least 1 Uppercase Character (a-z)",
    tu: "Devam et",
    ru: "Продолжить",
    fr: "Continuer",
  },
  AT_LEAST_1_NUMBER: {
    ar: "رقم واحد على الأقل (0-9)",
    en: "Has At Least 1 Number (0-9)",
    tu: "Devam et",
    ru: "Продолжить",
    fr: "Continuer",
  },
  MATCH_PASSWORDS: {
    ar: "",
    en: "Passwords are not Match",
    tu: "",
    ru: "",
    fr: "",
  },
  PASSWORDS_DO_NOT_MATCH: {
    ar: "كلمات المرور غير متطابقة",
    en: "The passwords do not match",
    tu: "Devam et",
    ru: "Продолжить",
    fr: "Continuer",
  },
  PASSWORD: {
    en: " Password",
    ar: " كلمة المرور",
    tu: "Şifreyi Onayla",
    ru: "Подтвердите Пароль",
    fr: "Confirmer le mot de passe",
  },
  CONFIRM_PASSWORD: {
    en: "Confirm Password",
    ar: "تأكيد كلمة المرور",
    tu: "Şifreyi Onayla",
    ru: "Подтвердите Пароль",
    fr: "Confirmer le mot de passe",
  },
  ORGANIZATION_NAME: {
    ar: "كلمه المرور",
    en: "Organization Name",
    tu: "Giriş Yap",
    ru: "Войти",
    fr: "S'identifier",
  },
  INDUSTRY: {
    ar: "كلمه المرور",
    en: "Industry",
    tu: "Giriş Yap",
    ru: "Войти",
    fr: "S'identifier",
  },
  JOB_TITLE: {
    ar: "الوظيفه",
    en: "Job title",
    tu: "Giriş Yap",
    ru: "Войти",
    fr: "S'identifier",
  },
  TITLE: {
    ar: "لقب المهنة",
    en: "Title",
    tu: "Başlık",
    ru: "Заголовок",
    fr: "Titre",
  },
  SWIPY: {
    ar: "Swipy",
    en: "Swipy",
    tu: "Giriş Yap",
    ru: "Войти",
    fr: "S'identifier",
  },
  SWIPY_DESC: {
    ar: " اكتشف نهجًا جديدًا ومبتكرًا للتفاعلات الرقمية، وتبسيط مشاركة المعلومات وتعزيز الاتصالات الهادفة، كل ذلك مع إعطاء الأولوية لرفاهية كوكبنا. ",
    en: "Discover a fresh and innovative approach to digital interactions, simplifying information sharing and fostering meaningful connections, all while prioritizing the well-being of our planet.",
    tu: "",
    ru: "",
    fr: "",
  },
  CARD_DESIGN: {
    ar: "تصميم البطاقة",
    en: "Card Design",
    tu: "",
    ru: "",
    fr: "",
  },
  SIGN_IN: {
    ar: "تسجيل الدخول",
    en: "Sign In",
    tu: "",
    ru: "",
    fr: "",
  },
  USERS: {
    ar: "المستخدمين",
    en: "Users",
    tu: "",
    ru: "",
    fr: "",
  },
  USER: {
    ar: "مستخدم",
    en: "User",
    tu: "",
    ru: "",
    fr: "",
  },
  TO_EMPLOYEE: {
    ar: " إلي الموظفين",
    en: "to Employees",
    tu: "",
    ru: "",
    fr: "",
  },
  FILE_CSV: {
    ar: "ورقة إكسيل",
    en: "File.csv",
    tu: "",
    ru: "",
    fr: "",
  },
  EMPLOYEES: {
    ar: "الموظفين",
    en: "Employees",
    tu: "",
    ru: "",
    fr: "",
  },
  INVOICES: {
    ar: "الفواتير",
    en: "Invoices",
    tu: "",
    ru: "",
    fr: "",
  },
  EMPLOYEES_PERMISSIONS: {
    ar: "يمكن للموظف تعديل رقم هاتفه",
    en: "Employee can edit his/her phone number",
    tu: "",
    ru: "",
    fr: "",
  },
  EMPLOYEES_PERMISSIONS_TOOLTIP: {
    ar: "يمكن للموظف تعديل رقم هاتفه او الواتساب او التيلجرام او السناب شات",
    en: "Employee can edit his/her phone number, whatsapp, telegram and snapchat",
    tu: "",
    ru: "",
    fr: "",
  },
  ALL_CONTANTS_WILL_EXPORT_TO_ANOTHER_EMPLOYEE: {
    ar: "جميع جهات الاتصال سوف تصدر إلى موظف آخر",
    en: "All Contacts will export to another Employee",
    tu: "",
    ru: "",
    fr: "",
  },
  ALL_CONTANTS_WILL_EXPORT_IN_FILE: {
    ar: "جميع جهات الإتصال سوف تصدر في ملف",
    en: "All Contacts will export in File",
    tu: "",
    ru: "",
    fr: "",
  },
  EXPORT_DONE_SUCCESSFULLY: {
    en: "Export Done Successfully",
    ar: " تم التصدير بنجاح",
    tu: "",
    ru: "",
    fr: "",
  },
  USER_NETWORK: {
    en: "User Network",
    ar: "شبكة المستخدم",
    tu: "",
    ru: "",
    fr: "",
  },
  UPLOADING_EXCEL_FILE: {
    ar: "",
    en: "Uploading Excel File",
    tu: "",
    ru: "",
    fr: "",
  },
  ERROR_MODAL_INFO: {
    ar: "",
    en: "salma testing",
    tu: "",
    ru: "",
    fr: "",
  },
  SUCCESS: {
    ar: "",
    en: "Success",
    tu: "",
    ru: "",
    fr: "",
  },
  PROFILE: {
    ar: 'الصفحه الشخصيه',
    en: 'Profile',
    tu: "Giriş Yap",
    ru: "Войти",
    fr: "S'identifier",
  },
  HAVE_ACCOUNT: {
    ar: "",
    en: "Do you have an account? ",
    tu: "",
    ru: "",
    fr: "",
  },
  BILLING: {
    ar: "",
    en: "Billing",
    tu: "",
    ru: "",
    fr: "",
  },
  PLANS_DESC: {
    ar: "",
    en: "We have powerful plans to Showcase your business and get discovered as a creative entrepreneurs. Every thing you need.",
    tu: "",
    ru: "",
    fr: "",
  },
  PLAN_TYPE: {
    ar: "",
    en: "Standard",
    tu: "",
    ru: "",
    fr: "",
  },
  PLAN_TYPE_STANDARD: {
    ar: "",
    en: "Standard",
    tu: "",
    ru: "",
    fr: "",
  },
  PLAN_ONE_USERS: {
    ar: "",
    en: "per user",
    tu: "",
    ru: "",
    fr: "",
  },
  PLAN_ONE_PROS1: {
    ar: "",
    en: "Everything in Solo",
    tu: "",
    ru: "",
    fr: "",
  },
  PLAN_ONE_PROS2: {
    ar: "",
    en: "Bulk Creation",
    tu: "",
    ru: "",
    fr: "",
  },
  PLAN_ONE_PROS3: {
    ar: "",
    en: "Template Management",
    tu: "",
    ru: "",
    fr: "",
  },
  PLAN_ONE_PROS4: {
    ar: "",
    en: "Everything in Team",
    tu: "",
    ru: "",
    fr: "",
  },
  PLAN_ONE_PROS5: {
    ar: "",
    en: "Integrations (e.g. Active Directory)",
    tu: "",
    ru: "",
    fr: "",
  },
  PLAN_ONE_PROS6: {
    ar: "",
    en: "Template Enforcement",
    tu: "",
    ru: "",
    fr: "",
  },
  PLAN_ONE_SUBSCRIBE: {
    ar: "انضمام",
    en: "Subscribe",
    tu: "",
    ru: "",
    fr: "",
  },
  UPDATE_PAYMENT_INFO: {
    ar: "",
    en: "Update Payment Method",
    tu: "",
    ru: "",
    fr: ""
  },
  PLAN_ONE_FREE_TRIAL: {
    ar: "شهر محاني",
    en: "1 Month Free Trial",
    tu: "",
    ru: "",
    fr: "",
  },
  PLAN_ONE_STARTED_FREE_TRIAL: {
    ar: "تواصل معنا",
    en: "You have started 1 month free trail",
    tu: "",
    ru: "",
    fr: "",
  },
  PLAN_TYPE_PLUS: {
    ar: "مميز",
    en: "Plus",
    tu: "",
    ru: "",
    fr: "",
  },
  PLAN_TWO_USERS: {
    ar: "تحدث مع مسئولي المبيعات",
    en: "Talk to Sales",
    tu: "",
    ru: "",
    fr: "",
  },
  PLAN_TWO_CONTACT_US: {
    ar: "تواصل معنا",
    en: "Contact Us",
    tu: "",
    ru: "",
    fr: "",
  },
  ORGANIZATION: {
    ar: "",
    en: "Organization",
    tu: "",
    ru: "",
    fr: "",
  },
  MY_ORGANIZATION: {
    ar: "",
    en: "My Organization",
    tu: "",
    ru: "",
    fr: "",
  },
  CHANGE_YOUR_ORGANIZATION: {
    ar: "",
    en: "Edit Organization Info",
    tu: "",
    ru: "",
    fr: "",
  },
  ORGANIZATION_NAME: {
    ar: "",
    en: "Organization Name",
    tu: "",
    ru: "",
    fr: "",
  },
  ORGANIZATION_SIZE: {
    ar: "",
    en: "Company Size",
    tu: "",
    ru: "",
    fr: "",
  },

  ORGANIZATION_INDUSTRY: {
    ar: "",
    en: "Industry",
    tu: "",
    ru: "",
    fr: "",
  },
  BACKGROUND_COLOR: {
    ar: "",
    en: "Background Color",
    tu: "",
    ru: "",
    fr: ""
  },
  PRIMARY_COLOR: {
    ar: "",
    en: "Primary Color",
    tu: "",
    ru: "",
    fr: "",
  },
  SECONDARY_COLOR: {
    ar: "",
    en: "Secondary Color",
    tu: "",
    ru: "",
    fr: "",
  },
  QR_COLOR: {
    ar: "",
    en: "Qr Color",
    tu: "",
    ru: "",
    fr: "",
  },
  QR_CODE_LAYOUT: {
    ar: "",
    en: "Qr Code Layout",
    tu: "",
    ru: "",
    fr: "",
  },
  FONT_COLOR: {
    ar: "",
    en: "Font Color",
    tu: "",
    ru: "",
    fr: "",
  },

  AUTOMATICALLY_USE_YOUR_LOGO: {
    ar: "",
    en: "Automatically use your logo",
    tu: "",
    ru: "",
    fr: "",
  },
  AUTOMATICALLY_USE_YOUR_FONT_COLOR: {
    ar: "",
    en: "Automatically use your font color",
    tu: "",
    ru: "",
    fr: "",
  },



  MENU: {
    ar: "",
    en: "Menu",
    tu: "",
    ru: "",
    fr: "",
  },
  LOG_OUT: {
    ar: "تسجيل الخروج",
    en: "Log Out",
    tu: "Çıkış Yap",
    ru: "Выйти",
    fr: "Se déconnecter",
  },
  MY_PROFILE: {
    en: "My Profile",
    ar: "ملفي الشخصي",
    tu: "Profilim",
    ru: "Мой профиль",
    fr: "Mon profil",
  },
  HELP_CENTER: {
    en: "Help Center",
    ar: "مركز الخدمه",
    tu: "Profilim",
    ru: "Мой профиль",
    fr: "Mon profil",
  },
  ACTIVE: {
    en: "Active ",
    ar: " نشط",
    tu: "Promosyon kodları",
    ru: "Промокоды",
    fr: "Code de promo"
  },
  USED: {
    en: "Used ",
    ar: " مستخدم",
    tu: "Promosyon kodları",
    ru: "Промокоды",
    fr: "Code de promo"
  },
  PROMO_CODES: {
    en: "Promo Codes",
    ar: "الرموز الترويجية",
    tu: "Promosyon kodları",
    ru: "Промокоды",
    fr: "Code de promo"
  },
  PROMO_CODE: {
    en: "Promo Code",
    ar: "الرمز الترويجي",
    tu: "Promosyon kodları",
    ru: "Промокоды",
    fr: "Code de promo"
  },
  HAVE_PROMO_CODE: {
    en: "Have Promo code ?",
    ar: "لديك رمز ترويجي",
    tu: "Promosyon kodları",
    ru: "Промокоды",
    fr: "Code de promo"
  },
  REDEEM_YOUR_PROMO_AND_ENJOY_WITH_THE_DISCOUND: {
    en: "Redeem your promo and enjoy with the discount",
    ar: "استبدل العرض الترويجي الخاص بك واستمتع بالخصم ",
    tu: "Promosyon kodları",
    ru: "Промокоды",
    fr: "Code de promo"
  },
  REDEEM_YOUR_PROMO_AND_ENJOY_WITH_THE_DISCOUND_IN_PRO_VERSION: {
    en: "Redeem your promo to enjoy discount in pro version.",
    ar: "استرد العرض الترويجي الخاص بك للاستمتاع بالخصم في الإصدار المحترف",
    tu: "Promosyon kodları",
    ru: "Промокоды",
    fr: "Code de promo"
  },
  REDEEM: {
    en: "Redeem",
    ar: "استرد",
    tu: "Promosyon kodları",
    ru: "Промокоды",
    fr: "Code de promo"
  },
  CONTINUE: {
    en: "Continue ",
    ar: "إستمر",
    tu: "Promosyon kodları",
    ru: "Промокоды",
    fr: "Code de promo"
  },
  APPLY: {
    en: "Apply",
    ar: "تابع",
    tu: "",
    ru: "",
    fr: ""
  },
  MY_CARD: {
    en: "My Card",
    ar: "بطاقتي",
    tu: "Kartım",
    ru: "Моя карта",
    fr: "Ma carte",
  },
  BILLING: {
    en: "Billing",
    ar: "الفواتير",
    tu: "Faturalandırma",
    ru: "Выставление счетов",
    fr: "Facturation",
  },
  NO_USERS_FOUND: {
    en: "Your contacts list is empty. Please either upload the Excel sheet to export your contacts, or alternatively, enter them manually.",
    ar: "ليس لديك اتصالات ، حاول جعلهم يشاركون بطاقات عملهم معك",
    tu: "",
    ru: "",
    fr: "",
  },
  NO_CARDS_FOUND: {
    en: "You don’t have business card add one",
    ar: "ليس لديك بطاقة عمل أضف واحدة",
    tu: "",
    ru: "",
    fr: "",
  },
  NO_PROMO_CODES_FOUND: {
    en: "No Promo codes found",
    ar: "لم يتم العثور على رموز ترويجية",
    tu: "",
    ru: "",
    fr: "",
  },
  DOWNLOAD_INVOICE: {
    en: "Download Invoice",
    ar: "",
    tu: "",
    ru: "",
    fr: "",
  },
  UPLOAD_EXCEL_SHEET: {
    en: "Upload Excel Sheet",
    ar: "تحميل ورقة Excel",
    tu: "",
    ru: "",
    fr: "",
  },
  UPLOAD_YOUR_DESIGN: {
    en: "Upload Your Design",
    ar: "قم بتحميل التصميم الخاص بك",
    tu: "",
    ru: "",
    fr: "",
  },
  DOWNLOAD_TEMPLETE: {
    en: "Download Template",
    ar: "تحميل القالب",
    tu: "",
    ru: "",
    fr: "",
  },
  NO_USERS: {
    en: "No Users",
    ar: "لا يوجد مستخدمين",
    tu: "",
    ru: "",
    fr: "",
  },
  LAST_EDIT: {
    en: "Last Edit",
    ar: "اخر تعديل",
    tu: "",
    ru: "",
    fr: "",
  },
  NAME: {
    en: "Name",
    ar: "الاسم",
    tu: "",
    ru: "",
    fr: "",
  },
  NAME_LANG1: {
    en: "Name Language 1",
    ar: "الاسم",
    tu: "",
    ru: "",
    fr: "",
  },
  NAME_LANG2: {
    en: "Name Language 2",
    ar: "الاسم",
    tu: "",
    ru: "",
    fr: "",
  },
  PHONE_NUMBER: {
    en: "Phone Number",
    ar: "رقم الهاتف",
    tu: "",
    ru: "",
    fr: "",
  },
  NO_CARDS: {
    en: "No.cards",
    ar: "رقم الكروت",
    tu: "",
    ru: "",
    fr: "",
  },
  ACTIONS: {
    en: "Actions",
    ar: "الإجراءات",
    tu: "",
    ru: "",
    fr: "",
  },
  DISPLAYED: {
    en: "Displayed",
    ar: "معروض",
    tu: "Sonraki",
    ru: "Далее",
    fr: "Suivant",
  },
  ROWS_PER_PAGE: {
    en: "Rows per page",
    ar: "صفوف في الصفحة",
    tu: "Sonraki",
    ru: "Далее",
    fr: "Suivant",
  },
  OUT_OF: {
    en: "Out Of",
    ar: "من",
    tu: "Sonraki",
    ru: "Далее",
    fr: "Suivant",
  },
  NEXT: {
    en: "Next",
    ar: "التالي",
    tu: "Sonraki",
    ru: "Далее",
    fr: "Suivant",
  },
  PREVIOUS: {
    en: "Previous",
    ar: "السابق",
    tu: "Sonraki",
    ru: ""
  },
  ADD_USER: {
    en: "Add User",
    ar: "إضافة مستخدم",
    tu: "Sonraki",
    ru: "Далее",
    fr: "Suivant"
  },
  ADD_CARD: {
    en: "Add Card",
    ar: "إضافة بطاقة",
    tu: "",
    ru: "",
    fr: ""
  },
  ADD_SUB_CARD: {
    en: "Add Sub Card",
    ar: "إضافة بطاقة",
    tu: "",
    ru: "",
    fr: ""
  },
  YOU_DONT_HAVE_ANY_CARD: {
    en: "You don’t have any cards please make it first the upload users",
    ar: "ليس لديك أي بطاقات، يرجى جعلها أولًا للمستخدمين الذين يقومون بالتحميل",
    tu: "",
    ru: "",
    fr: ""
  },
  YOU_DONT_HAVE_ANY_CARD_TO_ADD_USER: {
    en: "You don’t have any cards to add user",
    ar: "ليس لديك أي بطاقات لإضافة مستخدم",
    tu: "",
    ru: "",
    fr: ""
  },
  BROWSE: {
    en: "Browse",
    ar: "تصفح",
    tu: "",
    ru: "",
    fr: ""
  },
  DRAG_AND_DROP: {
    en: "Drag and drop files to upload",
    ar: "قم بسحب وإسقاط الملفات للتحميل",
    tu: "",
    ru: "",
    fr: ""
  },
  OR: {
    en: "or",
    an: "او",
    tu: "",
    ru: "",
    fr: ""
  },
  UPLOAD_FILE: {
    en: "Upload File",
    ar: "تحميل الملف",
    tu: "",
    ru: "",
    fr: ""
  },
  ALL: {
    en: "All",
    ar: "الكل",
    tu: "",
    ru: "",
    fr: ""
  },
  CORRECT: {
    en: "Correct",
    ar: "صحيح",
    tu: "",
    ru: "",
    fr: ""
  },
  ERROR: {
    en: "Error",
    ar: "خطأ",
    tu: "",
    ru: "",
    fr: ""
  },
  EXCEL_ERROR_MESSAGE: {
    en: "Are you sure you want to continue?",
    ar: "خطأ",
    tu: "",
    ru: "",
    fr: ""
  },
  ERROR_MODAL_MESSAGE: {
    en: "There is errors In your Excel sheet Submitting Data will remove the errored From your sheet, are you sure You want to continue review and submit?",
    ar: "خطأ",
    tu: "",
    ru: "",
    fr: ""
  },
  DATA_SUBMITTED_MESSAGE: {
    en: "Your Data Has been submitted successfully",
    ar: "خطأ",
    tu: "",
    ru: "",
    fr: ""
  },
  REVIEW_AND_SUBMIT: {
    en: "Review and Submit",
    ar: "مراجعة وإرسال",
    tu: "",
    ru: "",
    fr: ""
  },
  EXPORT_IN_EXCEL: {
    en: "Export in Excel Sheet",
    ar: "تصدير في ورقة Excel",
    tu: "",
    ru: "",
    fr: ""
  },
  EXPORT: {
    en: "Export",
    ar: "تصدير",
    tu: "",
    ru: "",
    fr: ""
  },
  EXPORT_TO_REGISTERED_EMPLOYEES: {
    en: "Export To Registered Employees",
    ar: " تصدير إلى الموظفين المسجلين",
    tu: "",
    ru: "",
    fr: ""
  },
  CONTACTS: {
    en: "Contacts",
    ar: "جهات الاتصال",
    tu: "",
    ru: "",
    fr: ""
  },
  POSITION: {
    en: "Position",
    ar: "الوظيفة",
    tu: "Konum",
    ru: "Позиция",
    fr: "Position"
  },
  POSITION_LAN1: {
    en: "Position Language 1",
    ar: "الوظيفة",
    tu: "Konum",
    ru: "Позиция",
    fr: "Position"
  },
  POSITION_LAN2: {
    en: "Position Language 2",
    ar: "الوظيفة",
    tu: "Konum",
    ru: "Позиция",
    fr: "Position"
  },
  COMPANY_LAN1:{
    ar: "اسم الشركة",
    en: "Company Language 1",
    tu: "Firma Adı",
    ru: "Название компании",
    fr: "Firma Adı"
  },
  COMPANY_LAN2:{
    ar: "اسم الشركة",
    en: "Company Language 2",
    tu: "Firma Adı",
    ru: "Название компании",
    fr: "Firma Adı"
  },
  COMPANY_NAME: {
    ar: "اسم الشركة",
    en: "Company Name",
    tu: "Firma Adı",
    ru: "Название компании",
    fr: "Firma Adı"
  },
  FACEBOOK: {
    en: "Facebook",
    ar: "فيسبوك",
    tu: "",
    ru: "",
    fr: ""
  },
  LINKED_IN: {
    en: "Linked In",
    ar: "لينكد إن",
    tu: "",
    ru: "",
    fr: ""
  },
  TELEGRAM: {
    en: "Telegram",
    ar: "Telegram",
    tu: "",
    ru: "",
    fr: ""
  },
  SNAPCHAT: {
    en: "Snapchat",
    ar: "Snapchat",
    tu: "",
    ru: "",
    fr: ""
  },
  SLACK: {
    en: "Slack",
    ar: "Slack",
    tu: "",
    ru: "",
    fr: ""
  },
  TWITTER: {
    en: "Twitter",
    ar: "Twitter",
    tu: "",
    ru: "",
    fr: ""
  },
  WHATSAPP: {
    en: "Whats app",
    ar: "Whats app",
    tu: "",
    ru: "",
    fr: ""
  },
  INSTERGRAM: {
    en: "Instagram",
    ar: "انستجرام",
    tu: "",
    ru: "",
    fr: ""
  },
  YOUTUBE: {
    en: "Youtube",
    ar: "يوتيوب",
    tu: "",
    ru: "",
    fr: ""
  },
  FAX: {
    en: "Fax",
    ar: "فاكس",
    tu: "",
    ru: "",
    fr: ""
  },
  TELEPHONE: {
    en: "Telephone",
    ar: "هاتف",
    tu: "",
    ru: "",
    fr: ""
  },
  DELETE_USER: {
    en: "Delete User",
    ar: "حذف المستخدم",
    tu: "",
    ru: "",
    fr: ""
  },
  CHOOSE_CARD: {
    en: "Choose Card",
    ar: "اختر بطاقة",
    tu: "",
    ru: "",
    fr: ""
  },
  USE_THIS_CARD: {
    en: "Use this card",
    ar: "استخدم هذا الكارت",
    tu: "",
    ru: "",
    fr: ""
  },
  DELETE: {
    en: "Delete",
    ar: "حذف",
    tu: "",
    ru: "",
    fr: ""
  },
  YES: {
    en: "Yes",
    ar: "نعم",
    tu: "",
    ru: "",
    fr: ""
  },
  NO: {
    en: "No",
    ar: "لا",
    tu: "",
    ru: "",
    fr: ""
  },
  ARE_YOU_SURE_TO_DELETE: {
    en: "Are you sure you want to delete this user?",
    ar: "هل أنت متأكد أنك تريد حذف هذا المستخدم؟",
    tu: "",
    ru: "",
    fr: ""
  },
  ARE_YOU_SURE_TO_DELETE_All_USERS: {
    en: "Are you sure you want to delete all users except admin?",
    ar: "هل أنت متأكد أنك تريد حذف هذا المستخدم؟",
    tu: "",
    ru: "",
    fr: ""
  },
  ARE_YOU_SURE_YOU_WANT_TO_CANCEL_EXCEL: {
    en: "Are you sure you want to cancel the Bulk upload, all employees in it will be removed.",
    ar: "هل أنت متأكد أنك تريد إلغاء التحميل المجمع، ستتم إزالة جميع الموظفين الموجودين فيه.",
    tu: "",
    ru: "",
    fr: ""
  },
  CANCEL_UPLOAD_EXCEL: {
    en: "Cancel uploading excel",
    ar: "الغاء تحميل الاكسل",
    tu: "",
    ru: "",
    fr: ""
  },
  NO_NOTIFICATION_FOUND: {
    en: "No Notification Found",
    ar: "لم يتم العثور على إشعارات",
    tu: "",
    ru: "",
    fr: ""
  },
  NOTIFICATION_DETAILS: {
    en: "Notification Details",
    ar: "تفاصيل الإشعار",
    tu: "",
    ru: "",
    fr: ""
  },
  INVOICE_ID: {
    en: "Invoice ID",
    ar: "معرف الفاتورة",
    tu: "",
    ru: "",
    fr: ""
  },
  INVOICE_DETAILS: {
    en: "Invoice Details",
    ar: "تفاصيل الفاتورة",
    tu: "",
    ru: "",
    fr: ""
  },
  AMOOUNT: {
    en: "Amount",
    ar: "كمية",
    tu: "",
    ru: "",
    fr: ""
  },
  DATE: {
    en: "Date",
    ar: "التاريخ",
    tu: "",
    ru: "",
    fr: ""
  },
  TIME: {
    en: "Time",
    ar: "الوقت",
    tu: "",
    ru: "",
    fr: ""
  },
  PAY_NOW: {
    en: "Pay Now",
    ar: "ادفع الان",
    tu: "",
    ru: "",
    fr: ""
  },
  STATUS: {
    en: "Status",
    ar: "الحالة",
    tu: "",
    ru: "",
    fr: ""
  },
  ACTION: {
    en: "Action",
    ar: "عمل",
    tu: "",
    ru: "",
    fr: ""
  },
  NO_INVOICES_FOUND: {
    en: "No invoices found",
    ar: "لم يتم العثور على فواتير",
    tu: "",
    ru: "",
    fr: ""
  },
  SEARCH: {
    en: "Search",
    ar: "ابحث",
    tu: "",
    ru: "",
    fr: ""
  },
  FILTER: {
    en: "Filter",
    ar: "فلتر",
    tu: "",
    ru: "",
    fr: ""
  },
  YOUR_PHOTO: {
    en: "Your Photo",
    ar: "صورتك",
    tu: "",
    ru: "",
    fr: ""
  },
  UPLOAD_PHOTO: {
    en: "Upload Photo",
    ar: "تحميل الصور",
    tu: "",
    ru: "",
    fr: ""
  },
  LOGO: {
    en: "Logo",
    ar: "شعار",
    tu: "",
    ru: "",
    fr: ""
  },
  UPLOAD_LOGO: {
    en: "Upload Logo",
    ar: "تحميل الشعار",
    tu: "",
    ru: "",
    fr: ""
  },
  PERSONAL_IFO: {
    en: "Personal Info",
    ar: "معلومات شخصية",
    tu: "",
    ru: "",
    fr: ""
  },
  YOUR_CONTANT: {
    en: "Your Contact",
    ar: "اتصالك",
    tu: "",
    ru: "",
    fr: ""
  },
  LINKS: {
    en: "Links",
    ar: "روابط",
    tu: "",
    ru: "",
    fr: ""
  },
  ASSIGN_TO_USERS: {
    en: "Assign to Users",
    ar: "تعيين للمستخدمين",
    tu: "",
    ru: "",
    fr: ""
  },
  NOTIFICATION: {
    en: "Notification",
    ar: "إعلام",
    tu: "",
    ru: "",
    fr: ""
  },
  NOTIFICATIONS: {
    en: "Notifications",
    ar: "إعلام",
    tu: "",
    ru: "",
    fr: ""
  },
  RESEND_CODE: {
    en: "Resend Code",
    ar: "إعادة إرسال الرمز",
    tu: "",
    ru: "",
    fr: ""
  },
  SEND: {
    en: "Send",
    ar: " ارسل ",
    tu: "",
    ru: "",
    fr: ""
  },
  STEP_ONE: {
    en: "Step 1",
    ar: "الخطوة 1",
    tu: "",
    ru: "",
    fr: ""
  },
  STEP_TWO: {
    en: "Step 2",
    ar: "الخطوة 2",
    tu: "",
    ru: "",
    fr: ""
  },
  STEP_THREE: {
    en: "Step 3",
    ar: "الخطوة 3",
    tu: "",
    ru: "",
    fr: ""
  },
  LAYOUT: {
    en: "Layout",
    ar: "تخطيط",
    tu: "",
    ru: "",
    fr: ""
  },
  ENTER_DATA: {
    en: "Enter Data",
    ar: "أدخل البيانات",
    tu: "",
    ru: "",
    fr: ""
  },
  ENTER_MESSAGE: {
    en: "Enter Message",
    ar: "أدخل الرساله",
    tu: "",
    ru: "",
    fr: ""
  },
  LUNACH: {
    en: "Lunach",
    ar: "إطلاق",
    tu: "",
    ru: "",
    fr: ""
  },
  UPDATE: {
    en: "Update",
    ar: "تحديث",
    tu: "",
    ru: "",
    fr: ""
  },
  STATISTICS: {
    en: "Statistics",
    ar: "الإحصائيات",
    tu: "",
    ru: "",
    fr: ""
  },
  SELECT_YEAR: {
    en: "select year",
    ar: "اختر السنة",
    tu: "",
    ru: "",
    fr: ""
  },
  NOT_REGISTERED: {
    en: "not registered",
    ar: " غير مسجل",
    tu: "",
    ru: "",
    fr: ""
  },
  FREE_TRIAL: {
    en: "Free Trial",
    ar: "تجربة مجانية",
    tu: "",
    ru: "",
    fr: ""
  },
  FREE_TRAIL_EXPIRED: {
    en: "Sorry, your free trial expired, you have to upgrade it to continue.",
    ar: "انتهت صلاحية التجربة المجانية ، يجب عليك ترقيتها للمتابعة",
    tu: "",
    ru: "",
    fr: ""
  },
  MONTH: {
    en: "Month",
    ar: "شهر",
    tu: "",
    ru: "",
    fr: ""
  },
  UPGRADE_DESC: {
    en: "Upgrade today to enjoy enhanced benefits. Don't miss out on all that Swipy has to offer.",
    ar: "قم بالترقية اليوم لتستمتع بالمزايا المحسنة. لا تفوت كل ما يقدمه Swipy.",
    tu: "",
    ru: "",
    fr: ""
  },
  ADDED_CARD: {
    en: "Your digital business card is ready to impress. Simply click 'Assign to Users' to share it with your connections.",
    ar: "بطاقة عملك الرقمية جاهزة لإثارة إعجابك. ما عليك سوى النقر فوق تعيين للمستخدمين لمشاركتها مع زملائك.",
    tu: "",
    ru: "",
    fr: ""
  },

  DAYS: {
    en: "Days",
    ar: "أيام",
    tu: "",
    ru: "",
    fr: ""
  },
  DAY: {
    en: "Day",
    ar: "يوم",
    tu: "",
    ru: "",
    fr: ""
  },
  CURRENT_NEW_PASSWORDS_SAME: {
    ar: "كلمة المرور الحالية وكلمة المرور الجديدة لا يمكن أن تكونا نفسهما!",
    en: "Current password and new password cannot be the same!",
    tu: "",
    ru: "",
    fr: ""
  },
  CONNECTION_ERROR: {
    ar: "خطأ في الاتصال!",
    en: "Connection Error!",
    tu: "Giriş Yap",
    ru: "Войти",
    fr: "S'identifier",
  },
  CHECK_YOUR_NETWORK: {
    ar: "يرجى التحقق من اتصالك بالإنترنت والمحاولة مرة أخرى.",
    en: "Please check your internet connection and try again.",
    tu: "Giriş Yap",
    ru: "Войти",
    fr: "S'identifier",
  },
  FILTER_BY_DATE: {
    ar: "تصفية حسب التاريخ",
    en: "Filter By Date",
    tu: "Giriş Yap",
    ru: "Войти",
    fr: "S'identifier",
  },
  COPY: {
    ar: "انسخ",
    en: "Copy",
    tu: "Giriş Yap",
    ru: "Войти",
    fr: "S'identifier",
  },
  COPIED_SUCCESSFULLY: {
    ar: "تم النسخ بنجاح",
    en: "Copied To Clipboard Successfully",
    tu: "Giriş Yap",
    ru: "Войти",
    fr: "S'identifier",
  },
  CARD_LANGUAGE: {
    en: "English Card",
    ar: "Arabic Card",
    fr: "French Card",
    ru: "Russian Card",
    tu: "Turkish Card",
  },
  CARD_LANGUAGE_2: {
    en: "English",
    ar: "Arabic",
    fr: "French",
    ru: "Russian",
    tu: "Turkish",
  },
  LANGUGES_VALUE: {
    en: "English",
    ar: "العربية",
    fr: "Français",
    ru: "русский",
  },
  YOUR_INVOICE_PAID: {
    en: "Your invoice paid successfully",
    ar: "تم دفع فاتورتك بنجاح",
    fr: "Votre facture a été payée avec succès",
    ru: "Ваш счет успешно оплачен",
    tu: "Faturanız başarıyla ödendi"
  },
  WRITE_WITH_CHOOSEN_DATA: {
    en: "Write data with the choosen language",
    ar: "يرجي كتابة البيانات باللغة المختارة",
    fr: "Écrire des données avec la langue choisie",
    ru: "Запись данных на выбранном языке",
    tu: "Verileri seçilen dille yazın"
  },
  UNSUBSCRIBE: {
    en: "Unsubscribe",
    ar: "",
    fr: "",
    tu: "",
    ru: ""
  },
  ARE_YOU_SURE_TO_UNSUBSCRIBE: {
    en: "Are you sure you want to unsubscribe ?",
    ar: "هل أنت متأكد أنك تريد إلغاء الاشتراك ؟",
    fr: "",
    tu: "",
    ru: ""
  },
  REQUEST_CUSTOM_DESIGN: {
    en: "Request custom design",
    ar: "طلب تصميم مخصص",
    fr: "Demander une conception personnalisée",
    tu: "Özel tasarım isteyin",
    ru: "Запросить индивидуальный дизайн"
  },
  JANUARY: {
    en: "January",
    ar: "يناير",
    tu: "Gelecek Yıl",
    ru: "Следующий год",
    fr: "L'année prochaine",
  },
  FEBRUARY: {
    en: "February ",
    ar: "فبراير",
    tu: "Gelecek Yıl",
    ru: "Следующий год",
    fr: "L'année prochaine",
  },
  MARCH: {
    en: "March  ",
    ar: "مارس",
    tu: "Gelecek Yıl",
    ru: "Следующий год",
    fr: "L'année prochaine",
  },
  APRIL: {
    en: "April",
    ar: "أبريل",
    tu: "Gelecek Yıl",
    ru: "Следующий год",
    fr: "L'année prochaine",
  },
  MAY: {
    en: "May ",
    ar: "مايو",
    tu: "Gelecek Yıl",
    ru: "Следующий год",
    fr: "L'année prochaine",
  },
  JUNE: {
    en: "June",
    ar: "يونيو",
    tu: "Gelecek Yıl",
    ru: "Следующий год",
    fr: "L'année prochaine",
  },
  JULY: {
    en: "July",
    ar: "يوليو",
    tu: "Gelecek Yıl",
    ru: "Следующий год",
    fr: "L'année prochaine",
  },
  AUGUST: {
    en: "August",
    ar: "أغسطس",
    tu: "Gelecek Yıl",
    ru: "Следующий год",
    fr: "L'année prochaine",
  },
  SEPTEMBER: {
    en: "September",
    ar: "سبتمبر",
    tu: "Gelecek Yıl",
    ru: "Следующий год",
    fr: "L'année prochaine",
  },
  OCTOBER: {
    en: "October",
    ar: "أكتوبر",
    tu: "Gelecek Yıl",
    ru: "Следующий год",
    fr: "L'année prochaine",
  },
  NOVEMBER: {
    en: "November",
    ar: "نوفمبر",
    tu: "Gelecek Yıl",
    ru: "Следующий год",
    fr: "L'année prochaine",
  },
   DESEMBER: {
    en: "December",
    ar: "ديسمبر",
    tu: "Gelecek Yıl",
    ru: "Следующий год",
    fr: "L'année prochaine",
  },
  CUSTOM: {
    en: "Custom",
    ar: "مخصص",
    tu: "Gelecek Yıl",
    ru: "Следующий год",
    fr: "L'année prochaine",
  },
  EXCEL_EDIT_EMPLOYEE_TEXT: {
    en: "Can employees edit their phone Numbers?",
    ar: "هل يمكن للموظفين تعديل أرقام هواتفهم؟",
    tu: "",
    ru: "",
    fr: ""
  },
  YOU_GONNA_ADD: {
    en: "You gonna to add",
    ar: "أنت ستعمل على إضافة",
    tu: "",
    ru: "",
    fr: ""
  },
  OF: {
    en: "of",
    ar: "من",
    tu: "",
    ru: "",
    fr: ""
  },
  THE_REST_OF: {
    en: "the rest of",
    ar: "بقية",
    tu: "",
    ru: "",
    fr: ""
  },
  REST_OF_EXCEL_MESSAGE: {
    en: "will not be added until you corrected their information, you can export, edit, and reupload from the Error list",
    ar: "لن تتم إضافتها حتى تقوم بتصحيح معلوماتها، ويمكنك التصدير والتحرير وإعادة التحميل من قائمة الأخطاء",
    tu: "",
    ru: "",
    fr: ""
  }
};


export default words;
