import { Suspense, lazy } from "react";
import { createBrowserRouter } from "react-router-dom";
import AuthLayout from "../components/layout/AuthLayout";
import Spinner from "react-bootstrap/esm/Spinner";
import MainLayout from "../components/layout/MainLayout";
import Error from "../components/layout/Error";
import { loader, tokenLoader } from "../utils/auth";


const lazyRetry = function (componentImport) {
  // debugger
  return new Promise((resolve, reject) => {
    // try to import the component
    // debugger
    componentImport()
      .then((component) => {
        // debugger
        resolve(component);
      })
      .catch((error) => {
        // TO DO
        // debugger;
        console.log(error);
        window.location.reload();
        reject(error); // there was an error
      });
  });
};

const LoginPage = lazy(() =>
  lazyRetry(() => import("../pages/auth/login/LoginPage"))
);
const SignUpPage = lazy(() =>
  lazyRetry(() => import("../pages/auth/signup/SignUpPage"))
);
const PlansPage = lazy(() =>
  lazyRetry(() => import("../pages/auth/plans/PlansPage"))
)
const ForgotPasswordPage = lazy(() =>
  lazyRetry(() => import("../pages/auth/forgetpassword/ForgotPassword"))
)
const CodeVerificationPage = lazy(() =>
  lazyRetry(() => import("../pages/auth/codeverification/CodeVerification"))
)
const ConfirmPasswordPage = lazy(() =>
  lazyRetry(() => import("../pages/auth/confirmpassword/ConfirmPassword"))
)

const UsersPage = lazy(() =>
  lazyRetry(() => import("../pages/main/users/UsersPage"))
)
const HomePage = lazy(() =>
  lazyRetry(() => import("../pages/main/home/HomePage"))
)
const ProfilePage = lazy(() =>
  lazyRetry(() => import('../pages/main/profile/ProfilePage'))
)
const NotificationsPage = lazy(() =>
  lazyRetry(() => import('../pages/main/notifications/NotificationsPage'))
)
const AddCardPage = lazy(() =>
  lazyRetry(() => import('../pages/main/home/AddCardPage'))
)
const AddSubCardPage = lazy(() =>
  lazyRetry(() => import('../pages/main/home/AddSubCardPage'))
)
const BillingPage = lazy(() =>
  lazyRetry(() => import('../pages/main/billing/BillingPage'))
)
const UserInfoPage = lazy(() =>
  lazyRetry(() => import('../pages/main/users/UserInfoPage'))
)
const UserDetails = lazy(() =>
  lazyRetry(() => import('../pages/main/users/UserDetails'))
)
const NewHome = lazy(() =>
  lazyRetry(() => import('../pages/main/newhome/Home'))
)


const Loading = (
  <div
    style={{
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      width: "100%",
      height: "100%",
    }}
  >
    <Spinner as="span" animation="border" variant="black" />
    {/* Loading..... */}
  </div>
);

export const router = createBrowserRouter([
  {
    path: "auth",
    errorElement: <Error />,
    loader: tokenLoader,
    element: <AuthLayout />,
    children: [
      {
        index: true,
        element: (
          <Suspense fallback={Loading}>
            <LoginPage />
          </Suspense>
        ),
      },
      {
        path: "sign-up",
        element: (
          <Suspense fallback={Loading}>
            <SignUpPage />
          </Suspense>
        )
      },
      {
        path: "forgot-password",
        element: (
          <Suspense fallback={Loading}>
            <ForgotPasswordPage />
          </Suspense>
        ),
      },
      {
        path: "code-verification",
        element: (
          <Suspense fallback={Loading}>
            <CodeVerificationPage />
          </Suspense>
        ),
      },
      {
        path: "confirm-password",
        element: (
          <Suspense fallback={Loading}>
            <ConfirmPasswordPage />
          </Suspense>
        ),
      },
    ],
  },
  {
    path: "",
    errorElement: <Error />,
    loader: loader,
    element: <MainLayout />,
    children: [
      {
        index: true,
        element: (
          <Suspense fallback={Loading}>
            <HomePage />
          </Suspense>
        ),
      },
      {
        path: "home",
        element: (
          <Suspense fallback={Loading}>
            <NewHome />
          </Suspense>
        ),
      },
      {
        path: "users",
        element: (
          <Suspense fallback={Loading}>
            <UsersPage />
          </Suspense>
        ),
      },
      {
        path: "users/details/:id",
        element: (
          <Suspense fallback={Loading}>
            <UserDetails />
          </Suspense>
        ),
      },
      {
        path: "users/add-user",
        element: (
          <Suspense fallback={Loading}>
            <UserInfoPage />
          </Suspense>
        ),
      },
      {
        path: "users/edit/:id",
        element: (
          <Suspense fallback={Loading}>
            <UserInfoPage />
          </Suspense>
        ),
      },
      {
        path: "billing",
        element: (
          <Suspense fallback={Loading}>
            <BillingPage />
          </Suspense>
        )
      },
      {
        path: "add-card",
        // loader: cardLoader,
        element: (
          <Suspense fallback={Loading}>
            <AddCardPage />
          </Suspense>
        ),
      },
      {
        path: "add-sub-card",
        // loader: cardLoader,
        element: (
          <Suspense fallback={Loading}>
            <AddSubCardPage />
          </Suspense>
        ),
      },
      {
        path: "update-sub-card/:id",
        // loader: cardLoader,
        element: (
          <Suspense fallback={Loading}>
            <AddSubCardPage />
          </Suspense>
        ),
      },
      {
        path: "update-card/:id",
        element: (
          <Suspense fallback={Loading}>
            <AddCardPage />
          </Suspense>
        ),
      },
      {
        path: "profile",
        element: (
          <Suspense fallback={Loading}>
            <ProfilePage />
          </Suspense>
        ),
      },
      {
        path: "notifications",
        element: (
          <Suspense fallback={Loading}>
            <NotificationsPage />
          </Suspense>
        ),
      }
    ]
  },
  {
    path: "plans",
    errorElement: <Error />,
    loader: loader,
    element: <Suspense fallback={Loading}>
      <PlansPage />
    </Suspense>
  },
  {
    path: "plans/:id",
    errorElement: <Error />,
    loader: loader,
    element: <Suspense fallback={Loading}>
      <PlansPage />
    </Suspense>
  },
  {
    path: "*",
    errorElement: <Error />,
    element: <Error />
  }
]);