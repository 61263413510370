import logo from "../../assets/images/black-logo.png";
import classes from "./SideMenu.module.scss";
import { FiUsers } from "react-icons/fi";
import { BiDollar } from "react-icons/bi";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useMemo, useState } from "react";
import words from "../../assets/words";
import { useLocation, useNavigate } from "react-router-dom";
import Button from "../ui/Button";
import rocket from "../../assets/images/rocket.png";
import { clearUsersIds, clearUsersPage } from "../../store/slices/users";
import { calcDiffrenceTime, dateDiffInDays, formatDate, formatTime } from "../../utils/formatWords";
import withModal from "../ui/withModal";
import ConfirmationMessage from "../ui/ConfirmationMessage";
import { TiBusinessCard } from "react-icons/ti";

const SideMenu = ({ setShowSideBar, planDetails }) => {
  const dispatch = useDispatch();
  const location = useLocation().pathname;
  const navigate = useNavigate();
  const { language, planData } = useSelector((state) => state.auth);

  const [showCancelEdit, setShowCancelEdit] = useState(false);
  const [activeTab, setActiveTab] = useState(location);
  const [currentTap, setCurrentTap] = useState(null);

  const tabs = [
    {
      icon: <TiBusinessCard size={25} />,
      text: words.CARD_DESIGN[language],
      href: "/",
    },
    {
      icon: <FiUsers />,
      text: words.EMPLOYEES[language],
      href: "/users",
    },
    {
      icon: <BiDollar />,
      text: words.BILLING[language],
      href: "/billing",
    },
  ];

  useEffect(() => {
    if (location.includes("update-card") || location.includes("add-card")) {
      setActiveTab("/");
    } else if (location.includes("add-user") || location.includes("edit") || location.includes("users/details")) {
      setActiveTab("/users");
    } else {
      setActiveTab(location);
    }
  }, [location, activeTab]);
    const handleChangeTab = (tab, index) => {
    setCurrentTap(tab)
    if (!location.includes("update-card") && !location.includes("add-card") && !location.includes("add-user") && !location.includes("edit")){
      setActiveTab(index);
      navigate(tab.href);
      dispatch(clearUsersIds());
      tab?.href !== "/users" &&
        dispatch(clearUsersPage())
    } else {
      if (!setShowSideBar) {
        setShowCancelEdit(true);
      } else {
        setActiveTab(index);
        navigate(tab.href);
        dispatch(clearUsersIds());
        tab?.href !== "/users" &&
          dispatch(clearUsersPage())
      }
    }
    setShowSideBar && setShowSideBar(false)
  }

  const CancelModal = useMemo(() => withModal(ConfirmationMessage), []);

  return (
    <div className={classes.sideMenu}>
      <CancelModal
        title={words.CANCEL_EDIT[language]}
        img
        message={words.DO_YOU_WANT_TO_DISCARD_YOUR_CHAGES[language]}
        show={showCancelEdit}
        onHide={() => setShowCancelEdit(false)}
        confirm={() => {
          setShowCancelEdit(false)
          navigate(currentTap?.href)
        }}
      />
      {/* <button className={classes.exitBtn} onClick={() => setShowSideBar(false)}><AiOutlineClose /></button> */}
      <img src={logo} alt="logo pic" />
      <small>{words.MENU[language]}</small>
      <div className={classes.content}>
        {tabs.map((tab, index) => {
          return (
            <div
              key={index}
              className={`${activeTab === tab.href ? classes.active : classes.unActive
                }`}
              onClick={() => handleChangeTab(tab, index)}
            >
              {tab.icon}
              <p>{tab.text}</p>
            </div>
          );
        })}
      </div>
      {
        (planDetails?.productType === "Enterprise(Standard)" || dateDiffInDays(
          formatDate(new Date()),
          formatDate(planDetails?.endDate)
        ) > 0) &&
        <div className={classes.upgrade}>
          <img src={rocket} alt="rocket" />
          {
            (planDetails?.isFreeTrial && planDetails?.isActive) ?
              (dateDiffInDays(
                formatDate(new Date()),
                formatDate(planDetails?.endDate)
              ) > 0) ? (
                <h5 className="text-white">
                  {dateDiffInDays(
                    formatDate(new Date()),
                    formatDate(planDetails?.endDate)
                  )}{" "}
                  {words.DAYS[language]} {words.FREE_TRIAL[language]}
                </h5>
              ) : calcDiffrenceTime(formatTime(new Date()), formatTime(planDetails?.endDate)) > 0 &&
              <h5 className="text-white text-center mx-2">
                Free trail ends today at {formatTime(planDetails?.endDate)}
                {/* {calcDiffrenceTime(formatTime(new Date()), formatTime(planDetails?.trial_end))} minutes */}
              </h5>
              :
              <></>
          }
          {
            (dateDiffInDays(
              formatDate(new Date()),
              formatDate(planDetails?.endDate)
            ) <= 0 && (planData&& typeof planData === 'string' && planData?.startsWith("'") && !JSON.parse(planData)?.isSubscribed))
              ?
              <p>Your Free Trail Is Ended</p>
              :
              <p>{words.UPGRADE_DESC[language]}</p>
          }
          <Button onClick={() => {
            setCurrentTap({
              href: "/plans/upgrade"
            })
            if (!location.includes("update-card")) {
              navigate("/plans/upgrade")
              dispatch(clearUsersPage())
            } else {
              setShowCancelEdit(true);
            }
          }}>
            {words.UPDATE_SUBSCRIPTION_DETTAILS[language]}
          </Button>
        </div>
      }

    </div>
  );
};
export default SideMenu;
