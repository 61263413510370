import { gql } from "@apollo/client";

export const READ_ADMIN_CARDS = gql`
query ReadAdminCards {
  readAdminCards {
    success
    message
    data {
      _id
      email
      phoneNumber
      countryCode
      font
      fontColor
      primaryColor
      secondaryColor
      cardTextAlignment
      logo
      isPro
      socialMediaLinks {
        providerName
        link
        isPublic
      }
      cardImage
      qrImage
      isCorporate
      userId
      cardID
      cardLink
      cardTheme
      isLocked
      DesignId
      qrColor
      isAdminCard
      assignQr
      updatedAt
      createdAt
      isPublic
      languages {
        fullName
        title {
          key
          value
        }
        position
        language
        cardTextAlignment
        company
      }
      backgroundColor
    }
    isAbleToAddCard
  }
}
`

export const HOME_QUERY = gql`
query ReadHomeQuery {
  readAdminCards {
    success
    message
    data {
      _id
      email
      phoneNumber
      countryCode
      font
      fontColor
      primaryColor
      secondaryColor
      cardTextAlignment
      logo
      isPro
      socialMediaLinks {
        providerName
        link
        isPublic
      }
      cardImage
      qrImage
      isCorporate
      userId
      cardID
      cardLink
      cardTheme
      DesignId
      qrColor
      isAdminCard
      assignQr
      updatedAt
      createdAt
      isPublic
      languages {
        fullName
        title {
          key
          value
        }
        position
        language
        cardTextAlignment
        company
      }
      backgroundColor
    }
  }
  
  readCorporateInformation {
    code
    success
    message
    data {
      main
      sub
    }
  }
}
`

export const READ_CORPORATE_INFO = gql`
query ReadCorporateInformation {
  readCorporateInformation {
    code
    success
    message
    data {
      usersCount
    }
  }
}
`
export const READ_CARD_INFO_BY_ID = gql`
query ReadBusinessCardById($readBusinessCardByIdId: ID, $cardId: String) {
  readBusinessCardById(id: $readBusinessCardByIdId, cardID: $cardId) {
    code
    message
    success
    data {
      phoneNumber
      updatedAt
      countryCode
      cardImage
      email
      totalScans
      languages {
        fullName
        position
      }
    }
  }
}
`
export const READ_BUSINESS_CARD_STATISTICS=gql`
query ReadBusinessCardStatistics($businessCardId: ID!, $year: Int!) {
  readBusinessCardStatistics(businessCardId: $businessCardId, year: $year) {
    message
    success
    code
    data {
      month
      count
    }
  }
}
`
export const READ_BUSINESS_CARD_CONTACTS = gql`
query ReadBusinessCardContacts($businessCardId: ID!, $page: Int!, $startDate: Date, $endDate: Date) {
  readBusinessCardContacts(businessCardId: $businessCardId, page: $page, startDate: $startDate, endDate: $endDate) {
    code
    success
    message
    data {
      pagination {
        totalDocuments
        viewLimit
      }
      list {
        createdAt
        cardImage
        countryCode
        email
        phoneNumber
        _id
        languages {
          position
          fullName
        }
      }
    }
  }
}

`
export const READ_CORPORATE_USERS = gql`
query ReadCorporateUsers($page: Int!, $searchInput: String, $businessCardId: ID!) {
  readCorporateUsers(page: $page, searchInput: $searchInput, businessCardId: $businessCardId) {
    code
    success
    message
    data {
      allContacts {
        email
        phoneNumber
        countryCode
        cardImage
        createdAt
        userId
        contactId
        languages {
          fullName
        }
      }
      pagination {
        totalDocuments
        viewLimit
      }
    }
  }
}
`
export const READ_ALL_BUSINESS_CARDS = gql`
query ReadCorporateBusinessCards($page: Int!, $searchInput: String) {
  readCorporateBusinessCards(page: $page, searchInput: $searchInput) {
    code
    success
    message
    data {
      list {
        _id
        isPublic
        email
        phoneNumber
        countryCode
        font
        fontColor
        backgroundColor
        primaryColor
        secondaryColor
        cardTextAlignment
        isAdminCard
        logo
        isPro
        socialMediaLinks {
          providerName
          link
          isPublic
        }
        cardImage
        qrImage
        isCorporate
        cardID
        cardLink
        cardTheme
        isLocked
        canEdit
        languages {
          fullName
          title {
          key
          value
        }
          position
          language
          company
          cardTextAlignment
        }
        userId
      }
      pagination {
        totalDocuments
        viewLimit
      }
      statistics {
        numberOfPublicCards
        numberOfPrivateCards
      }
    }
  }
}
`
export const READ_VALIDATED_DATA = gql`
query ReadValidatedData($page: Int) {
  readValidatedData(page: $page) {
    code
    data {
      list {
        countryCode
        email
        error {
          others
          email
          countryCode
          phoneNumber
          position
          socialMediaLinks {
            link
            providerName
          }
          title
        }
        phoneNumber
        socialMediaLinks {
          link
          providerName
        }
        title
        fullName_L1
        fullName_L2
        position_L1
        position_L2
        company_L1
        company_L2
      }
      pagination {
        totalDocuments
        viewLimit
      }
    }
    message
    success
  }
}
`
export const READ_REJECTED_DATA = gql`
query ReadRejectedData($page: Int) {
  readRejectedData(page: $page) {
    code
    data {
      list {
        countryCode
        email
        error {
          countryCode
          email
          others
          phoneNumber
          position
          socialMediaLinks {
            link
            providerName
          }
          title
          fullName_L1
          fullName_L2
          position_L1
          position_L2
          company_L1
          company_L2
        }
        phoneNumber
        socialMediaLinks {
          link
          providerName
        }
        title
        fullName_L1
        fullName_L2
        position_L1
        position_L2
        company_L1
        company_L2
      }
      pagination {
        totalDocuments
        viewLimit
      }
    }
    message
    success
  }
}
`

export const READ_CORPORATE_INVOICES = gql`
query ReadCorporateInvoices($page: Int!, $status: String, $endDate: Date, $startDate: Date, $searchInput: String) {
  readCorporateInvoices(page: $page, status: $status, endDate: $endDate, startDate: $startDate, searchInput: $searchInput) {
    code
    success
    message
    data {
      list {
        invoiceId
        invoiceNumber
        status
        quantity
        amountDue
        created
        InvoicePdf
        paid
      }
      pagination {
        totalDocuments
        viewLimit
      }
    }
  }
}
`

export const READ_SUBSCRIPTION_DETAILS = gql`
query ReadSubscriptionDetails {
  readSubscriptionDetails {
    code
    success
    message
    data {
      _id
      userId
      deviceType
      productType
      startDate
      endDate
      isFreeTrial
      isFreeTrialUsed
      isActive
      inActiveReason
      currency
      price
      country
      isSubscribed
    }
  }
}
`

export const READ_CORPORATE_MESSAGES = gql`
query ReadCorporateMessages($page: Int!) {
  readCorporateMessages(page: $page) {
    code
    success
    message
    data {
      list {
        _id
        subject
        body
        senderType
        inboxType
        generalId
        businessCardId
        recipient {
          userId
          isRead
          isDeleted
        }
        sender {
          _id
          fullName
          email
          isEmailVerified
          phoneNumber
          countryCode
          isCompletedInformation
          position
          corporateName
          corporateSize {
            key
            value
          }
          industry {
            key
            value
          }
          profilePictureUrl
          webFirebaseToken
          subscriptionId {
            _id
          }
        }
        createdAt
        updatedAt
        senderInfo {
          fullName
          profilePictureUrl
          subject
        }
      }
      pagination {
        totalDocuments
        viewLimit
      }
    }
  }
}
`

export const READ_NOTIFICATION_BADGE_NUMBER = gql`
query ReadCorporateNotifactionBadgeNumber {
  readCorporateNotifactionBadgeNumber {
    code
    success
    message
    data
  }
}
`
export const READ_TICKET_REASON = gql`
query ReadTicketReasonsList {
  readTicketReasonsList {
    code
    success
    message
    data {
      key
      value
      isDescriptionRequired
    }
  }
}
`

export const READ_TITLES = gql`
query ReadPrefixes {
  readPrefixes {
    code
    success
    message
    data {
      key
      value
    }
  }
}
`

export const READ_PROMOCODES = gql `
query ReadWebPromoCode($page: Int, $type: Type) {
  readWebPromoCode(page: $page, type: $type) {
    code
    success
    message
    data {
      list {
        id
        code
        name
        active
        status
        packageTypes
        startDate
        expires_at
        created
        percent_off
        amount_off
        durationInMonths
        max_redemptions
        times_redeemed
        pushNotification
        isAffilate
        affilateData {
          percentage
        }
      }
      pagination {
        totalDocuments
        viewLimit
      }
    }
  }
}
`

export const READ_PROMOCODE = gql `
query ReadPromoCode($promoCode: String!) {
  readPromoCode(promoCode: $promoCode) {
    code
    success
    message
    data {
      code
      name
      percent_off
      amount_off
      durationInMonths
    }
  }
}
`