
import classes from './PromoCodeRedeemModal.module.scss'
import words from '../../../assets/words';
import { useDispatch, useSelector } from 'react-redux';
import Input from '../../../components/ui/Input';
import Button from '../../../components/ui/Button';
import successIcon from '../../../assets/images/success icon.png'
import { useEffect, useState } from 'react';
import { useLazyQuery, useMutation } from '@apollo/client';
import { APPLY_PROMO_CODE } from '../../../graphql/main/mutations';
import { MdOutlineErrorOutline } from 'react-icons/md';
import { READ_PROMOCODE } from '../../../graphql/main/queries';
import { saveChangePromo } from '../../../store/slices/home';

const PromoCodeRedeemModal = ({ promoData, onSuccess }) => {
    const dispatch = useDispatch()
    const { language } = useSelector((state) => state.auth);

    const [promoValue, setPromoValue] = useState(promoData?.code || '')
    const [promoInfo, setPromoInfo] = useState("")
    const [error, setError] = useState("")

    const [readPromoCode, { loading: promoLoading }] = useLazyQuery(READ_PROMOCODE, {
        fetchPolicy: "cache-and-network",
        onCompleted: (data) => {
            if (data.readPromoCode.success) {
                setPromoInfo(data.readPromoCode.data)
                setError("")
            } else {
                setError(data.readPromoCode.message)
            }
        }
    })

    useEffect(() => {
        promoData?.code && readPromoCode({ variables: { promoCode: promoData?.code } })
    }, [promoData])

    const [applyPromoCode, { loading }] = useMutation(APPLY_PROMO_CODE, {
        onCompleted: (data) => {
            if (data.applyPromoCodeSubscription.success) {
                dispatch(saveChangePromo(true))
                onSuccess()
            } else {
                setError(data.applyPromoCodeSubscription.message)
            }
        }
    })

    const handleApply = () => {
        applyPromoCode({
            variables: {
                promoCode: promoValue
            }
        })
        setPromoInfo("")
    }
    const onRedeemBtnClicked = () => {
        readPromoCode({
            variables: {
                promoCode: promoValue
            }
        })
    }
    

    return (
        <div className={classes.container}>
            <div>
                <p>{words.REDEEM_YOUR_PROMO_AND_ENJOY_WITH_THE_DISCOUND_IN_PRO_VERSION[language]}</p>
            </div>
            <div className={classes.input}>
                <Input
                    value={promoValue}
                    onChange={(e) => {
                        setPromoValue(e.target.value?.toUpperCase())
                        setPromoInfo("")
                    }}
                    placeholder={words.PROMO_CODE[language]}
                    required
                    onKeyPress={(e) => {
                        if (e.key === 'Enter') {
                            onRedeemBtnClicked()
                        }
                    }}
                    icon={(promoInfo && promoValue) ? <img src={successIcon} alt='success' />
                        : <Button
                            onClick={onRedeemBtnClicked}
                            className={classes.redeemBtn}
                            disabled={!promoValue}
                            loading={promoLoading}
                            loadingSize={"sm"}
                        >{words.REDEEM[language]}</Button>
                    }
                    error={error}
                >
                </Input>

                {
                    error &&
                    <p className='text-danger'> <MdOutlineErrorOutline size={20} /> {error}</p>
                }

            </div>
            {(promoInfo && promoValue) && <div className={classes.promoInfo}>
                <div>
                    <p style={{ color: "var(--errorColor)" }}>Discount</p>
                </div>
                <div>
                    <p style={{ color: "var(--errorColor)" }}>{promoInfo.percent_off ? `${promoInfo.percent_off}%` : promoInfo.amount_off}</p>
                </div>
            </div>}
            {(promoInfo && promoValue) && <div className={classes.promoInfo}>
                <div>
                    <p style={{ color: "var(--primaryColor)",margin:0 }}>Valid till</p>
                </div>
                <div>
                    <p style={{paddingBottom:'1rem'}}>{promoInfo?.durationInMonths} Months</p>
                </div>
            </div>}
            <Button
                style={{ width: '80%' }}
                disabled={!promoValue}
                loading={loading}
                onClick={handleApply}
            >
                {words.APPLY[language]}
            </Button>
        </div>
    )
}

export default PromoCodeRedeemModal;