import { useDispatch, useSelector } from 'react-redux'
import Header from '../general/Header'
import SideMenu from '../general/SideMenu'
import classes from './MainLayout.module.scss'
import { Outlet, useNavigate } from "react-router-dom"
import { useEffect, useState } from 'react'
import Drawer from '@mui/material/Drawer';
import { saveSubscriptionData1 } from '../../store/slices/auth'
import { useLazyQuery } from '@apollo/client'
import { READ_SUBSCRIPTION_DETAILS } from '../../graphql/main/queries'
import { READ_USER_BY_ID } from '../../graphql/auth/queries'
import { authActions } from '../../store'

const MainLayout = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate()
    const { language } = useSelector(state => state.auth)
    const [showSideBar, setShowSideBar] = useState(false);
    const [planDetails, setPlanDetails] = useState(null);
    const [userData, setUserData] = useState(null);

    const [refetchSubInfo, { data: subscribeData }] = useLazyQuery(READ_SUBSCRIPTION_DETAILS, {
        fetchPolicy: "cache-and-network",
        onCompleted: (data) => {
            const arr = data.readSubscriptionDetails.data;
            setPlanDetails(arr);
        },
        onError: (error) => {
            if(error.message === "Please login to continue"){
                sessionStorage.clear()
                navigate("/auth")
            }
        }
    });

    const [refetchUserInfo, { data: userInfo }] = useLazyQuery(READ_USER_BY_ID, {
        fetchPolicy: 'cache-and-network',
    })

    useEffect(() => { //this is the code just for test the staging env until i change it by useSubscribe later
        refetchSubInfo().then(() => {
            refetchUserInfo().then(() => {
                refetchSubInfo().then((res2) => {
                    if (res2.data?.readSubscriptionDetails?.data) {
                        const arr = res2.data.readSubscriptionDetails.data;
                        if(arr?.isSubscribed){
                            dispatch(
                                saveSubscriptionData1({
                                    isFreeTrial:
                                       (arr?.isFreeTrial && arr?.isActive) ? true : false,
                                    isSubscribed:
                                        (arr.isActive) ? true : false,
                                })
                            );
                        } else {
                            navigate("/plans")
                        }
                    }
                    refetchUserInfo().then((res) => {
                        if (res?.data && !res?.data?.myCorporate?.subscriptionId?._id) {
                            sessionStorage.clear()
                            navigate("/auth")
                        } else {
                            dispatch(
                                authActions.saveUserInfo({ user: res?.data?.myCorporate })
                            );
                        }
                    })
                })
            })
        })
    },[])

    useEffect(() => {
        refetchSubInfo()
        refetchUserInfo()
        refetchSubInfo()
        refetchUserInfo()
    },[])

    useEffect(() => {
        userInfo && setUserData(userInfo?.myCorporate)
        if (userInfo) {
            if (userInfo && !userInfo?.myCorporate?.subscriptionId?._id) {
                // sessionStorage.clear()
                // navigate("/auth")
            } else {
                dispatch(
                    authActions.saveUserInfo({ user: userInfo?.myCorporate })
                );
            }
        }
    }, [userInfo])

    useEffect(() => {
        if (subscribeData?.readSubscriptionDetails?.data) {
            const arr = subscribeData.readSubscriptionDetails.data;
            dispatch(
                saveSubscriptionData1({
                    isFreeTrial:
                       (arr?.isFreeTrial && arr?.isActive) ? true : false,
                    isSubscribed:
                        (arr.isActive) ? true : false,
                })
            );
        }
    }, [subscribeData])


    return (
        <div className={classes.mainLayout} dir={language === "ar" ? "rtl" : "ltr"}>
            <div className={classes.sideMenu}>

                <SideMenu planDetails={planDetails} />
                <Drawer

                    open={showSideBar}
                    onClose={() => setShowSideBar(false)}
                >
                    <SideMenu setShowSideBar={setShowSideBar} showSideBar={showSideBar} planDetails={planDetails} />
                </Drawer>
            </div>
            <div className={classes.rightSide}>
                <div className={classes.header}>
                    <Header setShowSideBar={setShowSideBar} userData={userData} />
                </div>
                <div className={classes.main}>
                    <Outlet />
                </div>
            </div>
        </div>
    )
}

export default MainLayout