import Spinner from "react-bootstrap/Spinner";
import classes from "./Button.module.scss";

const Button = ({
  type = "button",
  children,
  onClick,
  disabled = false,
  style,
  loading = false,
  loadingSize
}) => {
  return (
    <button
      className={classes.button}
      type={type}
      onClick={onClick}
      disabled={disabled || loading}
      style={style}
    >
      {loading ? <Spinner as="span" animation="border" size={loadingSize}/> : children}
    </button>
  );
};

export default Button;
