import { redirect } from "react-router-dom";

export function loader() {
  const token = sessionStorage.getItem("token");
  const user = JSON.parse(sessionStorage.getItem("user"));
  const pathname = window.location.pathname;
  // || !user?.stripeData?.customer
  if ((!token || !user || !user?.isCompletedInformation ) && pathname !== "/auth") {
    return redirect("/auth");
  } else {
    return null;
  }
}

export function tokenLoader() {
  const token = sessionStorage.getItem("token");
  const user = JSON.parse(sessionStorage.getItem("user"));
  const pathname = window.location.pathname;
  // debugger
  if (token && user && user?.isCompletedInformation && user?.stripeData?.customer && pathname === "/auth") {
    return redirect("/");
  } else {
    return null;
  }
}

export function cardLoader() {
  const cardId = sessionStorage.getItem("cardId");
  if(cardId){
    return redirect("/");
  }else{
    return null;
  }
}
