import { createSlice } from "@reduxjs/toolkit";

export const home = createSlice({
    name: 'home',
    initialState: {
        cardData: JSON.parse(sessionStorage.getItem("cardData")) || null,
        currebtStep: JSON.parse(sessionStorage.getItem("currebtStep")) || 0,
        cardId: sessionStorage.getItem("cardId") || null,
        designId: sessionStorage.getItem("designId") || null,
        prev: JSON.parse(sessionStorage.getItem("prev")) || null,
        notificationNumber: 0,
        adminData: JSON.parse(sessionStorage.getItem("adminData")) || {},
        changeInfo: false,
        changeUsersInfo: false,
        changeInPromoCode: false
    },
    reducers: {
        saveCardData: (state, action) => {
            state.cardData = action.payload;
            sessionStorage.setItem("cardData", JSON.stringify(action.payload));
        },
        savePrevData: (state, action) => {
            state.prev = action.payload;
            sessionStorage.setItem("prev", JSON.stringify(action.payload));
        },
   
        saveCurrentStep: (state, action) => {
            state.currebtStep = action.payload;
            sessionStorage.setItem("currebtStep", JSON.stringify(action.payload));
        },
        saveCardIdAndDesignId: (state, action) => {
            state.cardId = action.payload.cardId;
            state.designId = action.payload.designId;
            sessionStorage.setItem("cardId", JSON.stringify(action.payload.cardId));
            sessionStorage.setItem("designId", JSON.stringify(action.payload.designId));
        },
        clearIdAndDesignId: (state) => {
            state.cardId = null;
            state.designId = null;
            sessionStorage.removeItem("cardId");
            sessionStorage.removeItem("designId");
        },
        saveNotificationNumber: (state, action) => {
            state.notificationNumber = action.payload;
            sessionStorage.setItem("badgeNumber", JSON.stringify(action.payload))
        },
        saveAdminData: (state, action) => {
            state.adminData = action.payload;
            sessionStorage.setItem("adminData", JSON.stringify({
                id: action.payload._id,
                languages: action.payload.languages
            }));
        },
        saveChangeInfo: (state, action) => {
            if(action.payload?.from === "users"){
                state.changeUsersInfo = action.payload.value
            } else {
                state.changeInfo = action.payload;
            }
        },
        saveChangePromo: (state, action) => {
            state.changeInPromoCode = action.payload
        }
    }
})

export const homeReducer = home.reducer;
export const { saveCardData, saveCurrentStep, saveCardIdAndDesignId, clearIdAndDesignId,savePrevData, saveNotificationNumber, saveAdminData, saveChangeInfo, saveChangePromo} = home.actions;