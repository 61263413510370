import { useSelector } from "react-redux";
import Button from "./Button";
import words from "../../assets/words";
import error from "../../assets/images/error.png";

function ErrorModal({onDone, content}) {
  const { language } = useSelector((state) => state.auth);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        // padding: "1rem 2rem",
      }}
    >
      <img src={error} alt="error pic"/>
      {/* <FaTimes size={50} color='red' style={{margin:"auto"}}/> */}
      <p className="fw-bold my-4 text-center">{content}</p>
      <Button
        style={{ width: "100%" }}
        onClick={onDone}
      >{words.DONE[language]}
      </Button>
    </div>
  );
}

export default ErrorModal;
