import { useState } from "react";
import SelectInput from "../ui/SelectInput";
import Textarea from "../ui/Textarea";
import words from "../../assets/words";
import { useSelector } from "react-redux";
import Button from "../ui/Button";
import classes from "./HelpCenterModal.module.scss";
import { useQuery } from "@apollo/client";
import { READ_TICKET_REASON } from "../../graphql/main/queries";

function HelpCenterModal({ onSend, DoneLoading }) {
  const { language } = useSelector((state) => state.auth);
  const [reasons, setReasons] = useState([]);
  const [data, setData] = useState({
    select: "",
    message: "",
  });

  useQuery(READ_TICKET_REASON, {
    onCompleted: (data) => {
      const arr = [];
      data?.readTicketReasonsList?.data.forEach((item) => {
        arr.push({
          label: item.value,
          value: item.key,
          isFixed: item.isDescriptionRequired,
        });
      });
      setReasons(arr);
    },
  });


  const handleOnchange = (e) => {
    setData({ ...data, select: e });
  }

  const handleTextArea = (e) => {
    setData({ ...data, message: e.target.value });
  };

  return (
    <div className={classes.container}>
      <div className={classes.wrapper}>
        <SelectInput
          required={true}
          onChange={handleOnchange}
          value={data.select}
          placeholder={words.CHOOSE_PROBLEM[language]}
          options={reasons}
          selectStyle={{
            width: "100%",
            margin: "auto",
          }}
        />

        <Textarea
          onChange={handleTextArea}
          value={data.message}
          placeholder={words.ENTER_MESSAGE[language]}
          required={data.select?.isFixed}
          areaStyle={{resize: "none"}}
        />
        <Button
          disabled={ (data.select?.isFixed && !data.message ) || DoneLoading || !data.select }
          loading={DoneLoading}
          style={{ margin: "auto", width: "100%", marginTop: "1rem" }}
          onClick={()=>onSend(data)}
        >
          {words.SEND[language]}
        </Button>
      </div>
    </div>
  );
}

export default HelpCenterModal;
