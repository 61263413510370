import { createSlice } from "@reduxjs/toolkit";

export const auth = createSlice({
    name: 'authx',
    initialState: {
        language: sessionStorage.getItem('language') || 'en',
        token: sessionStorage.getItem('token') || null,
        user: JSON.parse(sessionStorage?.getItem('user')) || null,
        organization: JSON.parse(sessionStorage.getItem('organization')) || null,
        email: sessionStorage.getItem('email') || null,
        planData: JSON.parse(sessionStorage.getItem('planData')) || null,
        firebaseToken: sessionStorage.getItem('firebaseToken') || null,
    },
    reducers: {
        saveLanguage: (state, action) => {
            state.language = action.payload.language;
            sessionStorage.setItem('language', action.payload.language);
        },
        saveEmail:(state,action) => {
            state.email = action.payload.email
            sessionStorage.setItem('email', action.payload.email)
        },
        saveToken: (state, action) => {
            state.token = action.payload;
            sessionStorage.setItem('token', action.payload);
        },
        saveUserInfo: (state, action) => {
            state.user = JSON.stringify(action.payload.user);
            sessionStorage.setItem("user", JSON.stringify(action.payload.user));
        },
        login: (state, action) => {
            const data= action.payload.user
            const userData = {
                id: data?._id,
                corporateName: data?.corporateName,
                corporateSize: data?.corporateSize,
                countryCode: data?.countryCode,
                email: data?.email,
                fullName: data?.fullName,
                industry: data?.industry,
                isCompletedInformation: data?.isCompletedInformation,
                isEmailVerified: data?.isEmailVerified,
                phoneNumber: data?.phoneNumber,
                position: data?.position,
                profilePictureUrl: data?.profilePictureUrl,
                stripeData: data?.stripeData,
                }
            state.user = userData;
            const userJson = JSON.stringify(userData);
            sessionStorage.setItem('user', userJson);

            state.token = action.payload.token;
            sessionStorage.setItem('token', action.payload.token);
        },

        logout: (state) => {
            state.user = null;
            state.token = null;
            state.email = null;
            state.organization = null;
            state.planData = null;
            state.firebaseToken = null;
            sessionStorage.removeItem('user');
            sessionStorage.removeItem('token');
            sessionStorage.clear()
        },
        saveBranchData: (state, action) => {
            const orgData = {
                providerId: action.payload.providerId,
                logoUrl: action.payload.logoUrl,
                branchId: action.payload.branchId,
                branchType: action.payload.branchType,
                name: action.payload.name,
                country: action.payload.country,
            }
            state.organization = orgData;
            const orgJson = JSON.stringify(orgData);
            sessionStorage.setItem('organization', orgJson);
        },
        saveSubscriptionData1: (state, action) => {
            state.planData = JSON.stringify(action.payload);
            sessionStorage.setItem('planData', JSON.stringify(action.payload));
        },
        saveFirebaseToken: (state, action) => {
            state.firebaseToken = action.payload;
            sessionStorage.setItem('firebaseToken', action.payload);
        },
        clearFirebaseToken: (state) => {
            state.firebaseToken = null;
            sessionStorage.removeItem('firebaseToken');
        }
    }
})

export const authReducer = auth.reducer;
export const { saveLanguage, login, logout, saveBranchData, saveEmail, saveToken, saveSubscriptionData1, saveFirebaseToken,clearFirebaseToken, saveUserInfo } = auth.actions;