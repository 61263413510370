import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import classes from "./Input.module.scss";
import { BsFillEyeFill, BsFillEyeSlashFill } from "react-icons/bs";

const Input = ({
  error = false,
  type = "text",
  name,
  placeholder,
  value = "",
  max,
  min = 1,
  style,
  className,
  required = false,
  disabled = false,
  dir,
  onChange = () => null,
  onFocus = () => null,
  onBlur = () => null,
  onKeyPress = () => null,
  iconTop,
  icon = null,
}) => {
  const language = useSelector((state) => state.auth.language);
  const [isActive, setIsActive] = useState(false);
  const [readOnly, setReadOnly] = useState(false);
  const [passwordVisibility, setPasswordVisibility] = useState(false);

  useEffect(() => {
    if (value === "" || value === null) {
      setIsActive(false);
    } else {
      setIsActive(true);
    }
  }, [value]);

  return (
    <div className={`${classes.container} ${className}`} style={style}>
      <label
        className={(isActive || value) && classes.inputFocus}
        htmlFor={name}
        style={language === "ar" ? { right: "3%" } :  dir ? {right : "3%"} : null}
      >
        {placeholder}{" "}
        {required && (
          <span style={{ color: "var(--errorColor)", textDecoration: "none" }}>
            {" "}
            *
          </span>
        )}
      </label>
      <span
        style={{
          position: "absolute",
          top: iconTop || "30%",
          [language === "ar" ? "left" : dir ? "left" : "right"]: "10px",
          
        }}
      >
        {type === "text" && <span>{icon}</span>}
        {type === "password" && (
          <span
            className={classes.inputIcon}
            onClick={() => setPasswordVisibility(!passwordVisibility)}
          >
            {passwordVisibility ? (
              <BsFillEyeFill color="gray" size={25} />
            ) : (
              <BsFillEyeSlashFill color="gray" size={25} />
            )}
          </span>
        )}
        {type === "email" && <span className={classes.inputIcon}>{icon}</span>}
      </span>
      <input
        type={passwordVisibility ? "text" : type}
        dir={(language === "ar" || dir) ? 'rtl' : 'ltr'}
        id={name}
        name={name}
        max={max}
        min={min}
        maxLength={max}
        value={value}
        disabled={disabled}
        readOnly={readOnly}
        onChange={(e) => {
          if (type === "number" && +e.target.value > max) {
            onChange({ target: { value: max } });
          } else if (type === "number" && +e.target.value < min) {
            onChange({ target: { value: "" } });
          } else {
            onChange(e);
          }
        }}
        onBlur={() => {
          setIsActive(value || false);
          if (value === 0) {
            setIsActive(true);
          }
          onBlur();
        }}
        onFocus={() => {
          setIsActive(true);
          setReadOnly(false);
          onFocus();
        }}
        onKeyDown={(e) => onKeyPress(e)}
        style={{
          borderColor: error ? "var(--errorColor)" : "#dddddd",
        }}
      />
    </div>
  );
};

export default Input;
