import { useSelector } from "react-redux";
import swipy from "../../assets/images/swipy.gif";
import Button from "./Button";
import words from "../../assets/words";

function DoneModal({ onDone, doneTitle, content, doneBody, doneBody2 }) {
  const { language } = useSelector((state) => state.auth);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        // padding: "1rem 2rem",
      }}
    >
      <img src={swipy} alt="swipy" />
      {content && <h5 className="my-4">{content}</h5>}
      {doneTitle &&
        <div style={{ textAlign: "center" }}>
          <p style={{ color: "grey" }}>Your Ticket has been Sent successfully</p>
          <b> Ticket Number <br /> {doneTitle}</b>
        </div>
      }
      {
        doneBody &&
        <div style={{ textAlign: "center", width: "85%" }}>
          <p style={{ color: "grey" }}>{doneBody}</p>
          <p style={{ fontWeight: "600", fontSize: "13px" }}>{doneBody2}</p>
        </div>
      }

      <Button
        style={{ width: "85%", marginTop: "8px" }}
        onClick={onDone}
      >{words.DONE[language]}
      </Button>
    </div>
  );
}

export default DoneModal;
