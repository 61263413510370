import "./App.css";
import { useEffect } from "react";
import { RouterProvider } from "react-router-dom";
import { router } from "./routes";
import { useSelector } from "react-redux";
import {
  ApolloClient,
  ApolloLink,
  ApolloProvider,
  InMemoryCache,
  from,
  split,
} from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { GraphQLWsLink } from "@apollo/client/link/subscriptions";
import { createClient } from "graphql-ws";
import { onError } from "@apollo/client/link/error";
import { getMainDefinition } from "@apollo/client/utilities";
import { createUploadLink } from "apollo-upload-client";

const protocol = window.location.protocol === "https:" ? "wss:" : "ws:";

const getCountryLinks = () => {
  let links = {
    database:
    "https://swipy-api-production-2a236470d731.herokuapp.com/graphql",
    monolith: "//swipy-api-production-2a236470d731.herokuapp.com/graphql",
  };
  return links;
};

const wsLink = (language, link) => {
  return new GraphQLWsLink(
    createClient({
      url: protocol + link.monolith,
      options: {
        reconnect: true,
      },
      connectionParams: {
        Authorization: sessionStorage.getItem("token"),
        language: language,
      },
    })
  );
}


const httpLink = (link) =>
  createUploadLink({
    uri: link.database,
  });

const splitLink = (language, token) =>
  split(
    ({ query }) => {
      const definition = getMainDefinition(query);
      return (
        definition.kind === "OperationDefinition" &&
        definition.operation === "subscription"
      );
    },
    wsLink(language, getCountryLinks(token)),
    httpLink(getCountryLinks(token))
  );

const authLink = (language, token) =>
  setContext((_, { headers }) => {
    return {
      headers: {
        language: language,
        ...headers,
        authorization: token && token !== "null" ? token : "",
        "keep-alive": "true",
        "content-type": "application/json",
      },
    };
  });

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors)
    graphQLErrors.forEach(({ message, locations, path }) => {
      if (message === "Please login to continue") {
        sessionStorage.clear();
        window.location.reload();
      }
    });
  if (networkError) console.log(`[Network error]: ${networkError}`);
});

const successLink = new ApolloLink((operation, forward) => {
  // Use the `forward` function to pass the operation down the middleware chain.
  return forward(operation).map((response) => {
    // This function is called when a response is received.
    const operationName = Object.keys(response.data)
    operationName.forEach((name) => {
      const code = response.data?.[name]?.code
      if (code === 450) {
        sessionStorage.clear()
        const redirectURL = 'https://dashboard.swipy.pro/'
        // const redirectURL = "http://localhost:3001"
        window.location.href = redirectURL
      }
    })
  
    // Return the response to continue the chain.
    return response;
  });
});

const createApolloClient = (language, token) => {
  return new ApolloClient({
    link: from([
      errorLink,
      successLink,
      authLink(language, token).concat(splitLink(language, token)),
    ]),
    cache: new InMemoryCache({
      dataIdFromObject: (o) => (o.id ? `${o.__typename}-${o.id}` : null),
    }),
    defaultOptions: {
      watchQuery: {
        errorPolicy: "all",
        returnPartialData: true,
      },
      query: {
        errorPolicy: "all",
        returnPartialData: true,
      },
    },
  });
};

function App() {
  const { language, token } = useSelector((state) => state.auth);

  const client = createApolloClient(language, token);

  useEffect(() => {
    sessionStorage.getItem("language")
      ? sessionStorage.getItem("language")
      : sessionStorage.setItem("language", "en");
  }, []);

  return (
    <ApolloProvider client={client}>
      <div>
        <RouterProvider router={router} />
      </div>
    </ApolloProvider>
  );
}

export default App;
