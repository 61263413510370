import { gql } from "@apollo/client";

export const READ_INDUSTRY_CATEGORY = gql`
  query Query {
    readIndustryCategory
  }
`;
export const READ_COMPANY_SIZE = gql`
  query Query {
  readCompanySizes {
    code
    success
    message
    data {
      key
      value
    }
  }
  }
`;

export const ORG_QUIRIES = gql`
query OrgQueries {
  readCompanySizes {
    code
    success
    message
    data {
      key
      value
    }
  }
  readIndustryCategories {
    code
    success
    message
    data {
      key
      value
    }
  }
}
`
export const READ_USER_BY_ID = gql `
query MyCorporate {
  myCorporate {
    _id
    fullName
    email
    isEmailVerified
    phoneNumber
    countryCode
    isCompletedInformation
    position
    corporateName
    profilePictureUrl
    webFirebaseToken
    industry {
      key
      value
    }
    corporateSize {
      key
      value
    }
    subscriptionId {
      _id
    }
  }
}
`
