import { useLocation, useNavigate } from "react-router-dom"
import classes from "./Header.module.scss"
import LanguageDropdown from "./LanguageDropdown"
import UserDropDown from "./UserDropDown"
import words from "../../assets/words"
import { useDispatch, useSelector } from "react-redux"
import { useEffect, useState } from "react"
import { HiOutlineMenu } from "react-icons/hi"
import { useLazyQuery, useMutation } from "@apollo/client"
import { READ_NOTIFICATION_BADGE_NUMBER } from "../../graphql/main/queries"
import { UPDATE_FIREBASE_TOKEN } from "../../graphql/main/mutations"
import { getFirebaseToken, onMessageListener } from "../../utils/firebase"
import "../../utils/firebase";
import { saveFirebaseToken } from "../../store/slices/auth"
import { IoNotifications } from "react-icons/io5"
import { Badge } from "@mui/material"
import { saveNotificationNumber } from "../../store/slices/home"

const Header = ({ setShowSideBar, userData }) => {
    const navigate = useNavigate();
    const location = useLocation().pathname;
    const dispatch = useDispatch()
    const { language, firebaseToken } = useSelector(state => state.auth);
    const { notificationNumber } = useSelector(state => state.home);
    const user = JSON.parse(sessionStorage.getItem("user"));
    const [pageTitle, setPageTitle] = useState("")
    const [notification, setNotification] = useState({
        show: false,
        data: null
    })
    const [newFirebaseToken, setNewFirebaseToken] = useState(firebaseToken);

    getFirebaseToken(setNewFirebaseToken);
  

    const [updateNumber] = useLazyQuery(READ_NOTIFICATION_BADGE_NUMBER, {
        fetchPolicy: 'cache-and-network',
        onCompleted: (data) => {
            dispatch(saveNotificationNumber(data?.readCorporateNotifactionBadgeNumber.data))
        }
    })

    const [updateFirebaseToken] = useMutation(UPDATE_FIREBASE_TOKEN, {
        fetchPolicy: 'no-cache',
        onCompleted: () => {
            dispatch(saveFirebaseToken(newFirebaseToken))
        }
    })


    onMessageListener().then(payload => {
        console.log("notification");
        setNotification({ ...notification, show: true, data: payload?.notification })
        updateNumber()
    }).catch((err) => console.log("failed: ", err))

    useEffect(() => {
        switch (location) {
            case "/":
                setPageTitle(words.MY_CARD[language])
                break;
            case "/users":
                setPageTitle(words.EMPLOYEES[language])
                break;
            case "/profile":
                setPageTitle(words.PROFILE[language])
                break;
            case "/billing":
                setPageTitle(words.BILLING[language])
                break;
            case "/add-card":
                setPageTitle(words.ADD_CARD[language])
                break;
            case "/notifications":
                setPageTitle(words.NOTIFICATIONS[language])
                break;
            default:
                setPageTitle("")
                break;
        }
        location.includes("/users/") ? setPageTitle(words.EMPLOYEES[language]) :
            (location.includes("/add-card") || location.includes("/update-card")) && setPageTitle(words.MY_CARD[language])
    }, [location,language])


    useEffect(() => {
        // setNotificationData([])
        if ((newFirebaseToken !== firebaseToken) && user) {
            updateFirebaseToken({
                variables: {
                    firebaseToken: newFirebaseToken,
                }
            })
        }
    }, [newFirebaseToken, firebaseToken, saveFirebaseToken]);

    useEffect(() => {
        updateNumber()
    }, [])

    return (
        <div className={classes.header}>
            <div className={classes.pageTitle}>
                <button onClick={() => { setShowSideBar(true) }}>
                    <HiOutlineMenu />
                </button>
                {pageTitle}
            </div>
            <div className={classes.info}>
                <LanguageDropdown />
                <div onClick={() => navigate("/notifications")} style={{marginRight: "10px "}}>
                    <Badge badgeContent={notificationNumber} color="primary" style={{ cursor: "pointer" }}>
                        <IoNotifications color={notificationNumber ? "var(--darkYellow)" : "var(--greyColor800)"} icon="bell" size={20} />
                    </Badge>
                </div>
                <UserDropDown userData={userData} />
            </div>
        </div>
    )
}

export default Header