import firebase from 'firebase/compat/app';
import { getMessaging, getToken, onMessage } from "firebase/messaging";

const firebaseConfig = {
    apiKey: "AIzaSyA7JfbEdjLoPQgJxLveu8oMCaue0br5j04",
    authDomain: "swipy-449b9.firebaseapp.com",
    projectId: "swipy-449b9",
    storageBucket: "swipy-449b9.appspot.com",
    messagingSenderId: "988964749339",
    appId: "1:988964749339:web:b555f70deec069de8278be",
    measurementId: "G-P49MGRNEVF"
  };

const firebaseApp = firebase.initializeApp(firebaseConfig);
const messaging = getMessaging(firebaseApp);


export const getFirebaseToken = (setFirebaseToken) => {
    // return getToken(messaging, { vapidKey: process.env.REACT_APP_FIREBASE_VAPID_KEY }).then((currentToken) => {
    return getToken(messaging, { vapidKey: 'BFCcXPSJqHQkvgs1XPVQ4T96kBKj3k5z6FwGEXotgx2HbXVJ92hPDfaaK282z3Xu8KgV47LBLfGWJrRiX0NH_14' }).then((currentToken) => {
        if (currentToken) {
            setFirebaseToken(currentToken);
            // return currentToken;
            // Track the token -> client mapping, by sending to backend server
            // show on the UI that permission is secured
        } else {
            // debugger
            // console.log('No registration token available. Request permission to generate one.');
            setFirebaseToken(null);
            // shows on the UI that permission is required 
        }
    }).catch((err) => {
        console.log('An error occurred while retrieving token. ', err);
        // catch error while creating client token
    });
}

export const onMessageListener = () => {
    return new Promise((resolve) => {
        onMessage(messaging, (payload) => {
            console.log('payload', payload);
            console.log("sucusas",messaging);
            resolve(payload);
        });
    });
}
