import Spinner from 'react-bootstrap/esm/Spinner'

const SpinnerApp = () => {
    return (
        <div  style={{width: "100%", textAlign: "center",height:'100%',display:'flex',alignItems:'center',justifyContent:'center'}}>
            <Spinner as="span" animation="border" variant="black"/>
        </div>
    )
}

export default SpinnerApp